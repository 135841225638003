import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import HomeLayout from '@/layout/HomeLayout'
Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/artifact',
      component: DashboardLayout,


      children: [
        {
          path: '/artifact/doclist',
          name: 'documents',
          component: () => import('./views/process/documentList.vue'),
          props: true,
        },
        {
          path: '/artifact/live',
          name: 'live',
          component: () => import('./views/process/documentList.vue'),
          props: true,
        },
        {
          path: '/artifact/document',
          name: 'new1',
          component: () => import('./views/process/documents/new.vue'),
          props: true

        },
        {
          path: '/artifact/clone',
          name: 'clone',
          component: () => import('./views/process/documents/new.vue'),
          props: true
        },
        

        
      ]
    },

    {
      path: '/dashboard',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('./views/Dashboard.vue'),
          props: { docStatusType: 'Batch', 'showTimeLine': true, }
        },

        {
          path: '/icons',
          name: 'icons',
          component: () => import('./views/Icons.vue'),
          props: true
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('./views/UserProfile.vue'),
          props: true
        },

        // {
        //   path: '/documents/live',
        //   name: 'documents',
        //   component: () => import('./views/process/documentListv1.vue'),
        //   props: { docStatusType: 'Batch' }
        // },
        // {
        //   path: '/documents/draft',
        //   name: 'documents',
        //   component: () => import('./views/process/documentListv1.vue'),
        //   props: { docStatusType: 'Draft' },
        // },
        // {
        //   path: '/documents/approved',
        //   name: 'documents',
        //   component: () => import('./views/process/documentListv1.vue'),
        //   props: { docStatusType: 'Approved' }
        // },
        // {
        //   path: '/documents/archived',
        //   name: 'documents',
        //   component: () => import('./views/process/documentListv1.vue'),
        //   props: { docStatusType: 'Archived' }
        // },
        // {
        //   path: '/documents/shared',
        //   name: 'documents',
        //   component: () => import('./views/process/documentListv1.vue'),
        //   props: { docStatusType: 'Shared' }
        // },
        // {
        //   path: '/documents/versionup',
        //   name: 'documents',
        //   component: () => import('./views/process/documentListv1.vue'),
        //   props: { docStatusType: 'Versionup' }
        // },
        {
          path: '/golive',
          name: 'golive',
          component: () => import('./views/process/documentListv1.vue'),
          props: { docStatusType: 'Batch' }
        },
        {
          path: '/documents',
          name: 'documents',
          component: () => import('./views/process/documentListv1.vue'),
          props: true
        },
        {
          path: '/new',
          name: 'new',
          component: () => import('./views/process/documentsv1/new.vue'),
          props: true
        },
        {
          path: '/batch',
          name: 'batch',
          component: () => import('./views/process/batchv1/batch.vue'),
          props: true
        },
        // {
        //   path: '/artifact/batch',
        //   name: 'batch',
        //   component: () => import('./views/process/batch/batch.vue'),
        //   props: true
        // },
        {
          path: '/showDataTables',
          name: 'showDataTables',
          component: () => import('./views/process/showDataTables.vue'),
          props: true
        },
        {
          path: '/HomeChat',
          name: 'HomeChat',
          component: () => import('./views/HomeChat.vue'),
          props: true
        },
        {
          path: '/tables',
          name: 'tables',
          component: () => import('./views/Tables.vue'),
          props: true
        },
        {
          path: '/Notepad',
          name: 'Notepad',
          component: () => import('./views/process/notepad/Notepad.vue'),
          props: true
        },
        // {
        //   path: '/batch',
        //   name: 'batch',
        //   component: () => import('./views/process/batch/batch.vue'),
        //   props: true
        // },
        {
          path: '/notepadbatch',
          name: 'notepadbatch',
          component: () => import('./views/process/notepad/notepadbatch.vue'),
          props: true
        },
        // {
        //   path: '/live',
        //   name: 'live',
        //   component: () => import('./views/process/Notepad.vue'),
        //   props: true,
        // },
        {
          path: '/newNotepad',
          name: 'newNotepad',
          component: () => import('./views/process/notepad/newNotepad.vue'),
          props: true
        },
        {
          path: '/pdf',
          name: 'pdf',
          component: () => import('./views/process/Pdf/pdf.vue'),
          props: true
        },
        {
          path: '/library',
          name: 'library',
          component: () => import('./views/process/Library/libraryDocuments.vue'),
          props: true
        },
        {
          path: '/newLibrary',
          name: 'newLibrary',
          component: () => import('./views/process/Library/newLibrary.vue'),
          props: true
        },
        {
          path: '/template',
          name: 'template',
          component: () => import('./views/process/Template/templateDocuments.vue'),
          props: true
        },
        {
          path: '/newTemplate',
          name: 'newTemplate',
          component: () => import('./views/process/Template/newTemplate.vue'),
          props: true
        },
        {
          path: '/pdfbatch',
          name: 'pdfbatch',
          component: () => import('./views/process/Pdf/pdfBatch.vue'),
          props: true
        },
        {
          path: '/newPdf',
          name: 'newPdf',
          component: () => import('./views/process/Pdf/newPdf.vue'),
          props: true
        },
        {
          path: '/formsBatch',
          name: 'formsBatch',
          component: () => import('./views/process/forms/formsBatch.vue'),
          props: true
        },
        {
          path: '/forms',
          name: 'forms',
          component: () => import('./views/process/forms/forms.vue'),
          props: true
        },
        {
          path: '/newForms',
          name: 'newForms',
          component: () => import('./views/process/forms/newForms.vue'),
          props: true
        },
        {
          path: '/usersList',
          name: 'usersList',
          component: () => import('./views/process/users/usersList.vue'),
          props: true
        },
        {
          path: '/newUsers',
          name: 'newUsers',
          component: () => import('./views/process/users/newUsers.vue'),
          props: true
        },
        {
          path: '/viewUsageUsers',
          name: 'viewUsageUsers',
          component: () => import('./views/process/users/usageUsersList.vue'),
          props: true
        },
        {
          path: '/departmentList',
          name: 'departmentList',
          component: () => import('./views/process/departments/departmentList.vue'),
          props: true
        },
        {
          path: '/newDepartment',
          name: 'newDepartment',
          component: () => import('./views/process/departments/newDepartment.vue'),
          props: true
        },
        {
          path: '/newEquipment',
          name: 'newEquipment',
          component: () => import('./views/process/documents/newEquipment.vue'),
          props: true
        },
        {
          path: '/newReagent',
          name: 'newReagent',
          component: () => import('./views/process/documents/newReagent.vue'),
          props: true
        },
        {
          path: '/newSampleManagement',
          name: 'newSampleManagement',
          component: () => import('./views/process/documents/newSampleManagement.vue'),
          props: true
        },
        {
          path: '/newDeviation',
          name: 'newDeviation',
          component: () => import('./views/process/documents/newDeviation.vue'),
          props: true
        },
        {
          path: '/newCapa',
          name: 'newCapa',
          component: () => import('./views/process/documents/newCapa.vue'),
          props: true
        },
        {
          path: '/newEvent',
          name: 'newEvent',
          component: () => import('./views/process/documents/newEvent.vue'),
          props: true
        },
        {
          path: '/newImages',
          name: 'newImages',
          component: () => import('./views/process/documents/newImages.vue'),
          props: true
        },
        {
          path: '/viewUsageSampleManagement',
          name: 'viewUsageSampleManagement',
          component: () => import('./views/process/usageSampleManagement.vue'),
          props: true
        },
        {
          path: '/viewUsageEquipment',
          name: 'viewUsageEquipment',
          component: () => import('./views/process/usageEquipmentList.vue'),
          props: true
        },
        {
          path: '/viewUsageReagent',
          name: 'viewUsageReagent',
          component: () => import('./views/process/usageReagentList.vue'),
          props: true
        },
        {
          path: '/viewUsageDeviation',
          name: 'viewUsageDeviation',
          component: () => import('./views/process/usageDeviationList.vue'),
          props: true
        },
        {
          path: '/viewUsageCapa',
          name: 'viewUsageCapa',
          component: () => import('./views/process/usageCapaList.vue'),
          props: true
        },
        {
          path: '/viewUsageEvent',
          name: 'viewUsageEvent',
          component: () => import('./views/process/usageEventList.vue'),
          props: true
        },
        {
          path: '/stickyNotes',
          name: 'stickyNotes',
          component: () => import('./views/process/stickyNotes.vue'),
          props: true
        },
        {
          path: '/sharedNotes',
          name: 'sharedNotes',
          component: () => import('./views/process/sharedNotes.vue'),
          props: true
        },
        {
          path: '/flowChart',
          name: 'flowChart',
          component: () => import('./views/process/flowChart/flowChart.vue'),
          props: true
        },
        {
          path: '/newFlowchart',
          name: 'newFlowchart',
          component: () => import('./views/process/flowChart/newFlowchart.vue'),
          props: true
        },
        {
          path: '/flowChartBatch',
          name: 'flowChartBatch',
          component: () => import('./views/process/flowChart/flowChartBatch.vue'),
          props: true
        },
        {
          path: '/planner2',
          name: 'planner2',
          component: () => import('./views/process/planner.vue'),
          props: true
        },
        {
          path: '/planner',
          name: 'planner',
          component: () => import('./views/process/planner.vue'),
          props: true
        },
        {
          path: '/calendar',
          name: 'calendar',
          component: () => import('./views/process/calendar.vue'),
          props: true
        },
        {
          path: '/equipmentList',
          name: 'equipmentList',
          component: () => import('./views/process/equipmentList.vue'),
          props: true
        },
        {
          path: '/reagentList',
          name: 'reagentList',
          component: () => import('./views/process/reagentList.vue'),
          props: true
        },
        {
          path: '/sampleManagement',
          name: 'sampleManagement',
          component: () => import('./views/process/sampleManagement.vue'),
          props: true
        },
        {
          path: '/deviation',
          name: 'deviation',
          component: () => import('./views/process/deviation.vue'),
          props: true
        },
        {
          path: '/capa',
          name: 'capa',
          component: () => import('./views/process/capa.vue'),
          props: true
        },
        {
          path: '/event',
          name: 'event',
          component: () => import('./views/process/eventList.vue'),
          props: true
        },
        {
          path: '/files',
          name: 'files',
          component: () => import('./views/process/files.vue'),
          props: true
        },
        {
          path: '/videos',
          name: 'videos',
          component: () => import('./views/process/videos.vue'),
          props: true
        },
        {
          path: '/images',
          name: 'images',
          component: () => import('./views/process/images.vue'),
          props: true
        },
        {
          path: '/datacenter',
          name: 'datacenter',
          component: () => import('./views/process/dataCenter.vue'),
          props: true
        },
        {
          path: '/details',
          name: 'details',
          component: () => import('./views/process/documents/detailsView.vue'),
          props: true
        },
        {
          path: '/changePassword',
          name: 'changePassword',
          component: () => import('./views/ChangePassword.vue'),
          props: true
        }

      ]
    },
    {
      path: '/',

      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('./views/Home.vue'),
          meta: {
            public: true
          },
          props: true
        }
      ]
    },
    {
      path: '/',

      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('./views/Login.vue'),
          meta: {
            public: true
          },
          props: true
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('./views/Register.vue'),
          meta: {
            public: true
          },
          props: true
        },
        {
          path: '/activate',
          name: 'activate',
          component: () => import('./views/Activate.vue'),
          meta: {
            public: true
          },
          props: true
        },
        {
          path: '/sharedDocument/:key',
          name: 'sharedDocument',
          component: () => import('./views/process/documents/new.vue'),
          meta: {
            public: true
          },

        },
        {
          path: '/sharedProject/:key',
          name: 'sharedProject',
          component: () => import('./views/process/timeLine.vue'),
          meta: {
            public: true
          },

        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  // const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)

  function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    } else {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  var authToken = getCookie("auth-token");
  // if (!authToken) {
  //   this.$router.push("/login");
  // }
  const loggedIn = !!authToken;

  if (!isPublic && !loggedIn) {
    next({
      path: '/',
      // query: { redirect: to.fullPath }  // Store the full path to redirect the user to after login
    });
  }
  if (loggedIn && to.path == '/') {
    next({
      path: '/dashboard'
    });
  }
  // if (!isPublic && !loggedIn) {
  //   next({
  //     path: '/login',
  //     query: { redirect: to.fullPath }  // Store the full path to redirect the user to after login
  //   });
  // }

  // // Do not allow user to visit login page or register page if they are logged in
  // if (loggedIn) {
  //   return next('/')
  // }

  next();

})


export default router;