<template>
  <div>
    <div style="clear: both; height: 270px;">
      <document-header
        :docStatusType="docStatusType"
        :templateType="templateType"
      ></document-header>
      <div class="container-fluid">
        <div class="row">
          <div class="doc-header" v-if="documentTemp.title">
            {{
              stripHTML(documentTemp.title)+ " - " + documentTemp.batchNumber
            }}
          </div>
        </div>
        <button class="btn-default1 btn-sm btn-step-action1 view-batch"
        v-if="documentTemp && documentTemp.sections && showViewBatchDataButton()"
        @click="viewList()"
        >View Batch Data</button>      
        <div class="col editControlParent">
          <!-- <div id="editorControlListLeft">
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="text-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/text.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Text</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="image-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/image.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Image</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="video-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/video.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Video</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/timer.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Timer</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="formula-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/formula-fx.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Formula</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="qrcode-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/media.jpg" alt="avatar" class="u-photo" />
              <span class="leftIcon">QR</span>
            </div>
          </div> -->
        </div>
        <div class="col editorBatch">
          <div class="card shadowBatch">
            <div class="contacts"></div>
            <div class="card-body">
              <div
                v-if="documentTemp.title"
                v-html="documentTemp.title"
                class="form-control-alternative"
              ></div>
              <div v-if="!documentTemp.title">
                <!-- <textarea v-model.lazy="tempDocTitle" style="width: 100%"></textarea> -->
                <input type="text"
                  :id="getId()"
                  class="form-control-alternative"
                />
                <!-- <base-button
                  size="sm"
                  type="default"
                  class="float-right"
                  @click="addTitle($event)"
                >Add Title</base-button>-->
              </div>
              <div
                v-for="(section, sec_index) in documentTemp.sections"
                :key="section.id || section.title"
              >
                <div class="sec-toggle sec-section" @click="sectionToggle">
                  <label
                    class="form-control-label"
                    style="margin:7px"
                  >{{("Section " + (sec_index+1) + " : ")}}</label>
                  <label
                    class="form-control-label"
                    v-if="section.description"
                    v-html="section.description"
                  ></label>
                  <label
                    class="form-control-label ctrl-collapse"
                    style="margin-right:15px;display:none;float:right;"
                  >+</label>
                  <label
                    class="form-control-label ctrl-expand"
                    style="margin-right:15px;float:right;"
                  >-</label>
                </div>
                <div class="sec-body step-section">
                  <!-- <div
                    v-if="section.description"
                    v-html="section.description"
                    class="form-control-alternative"
                  ></div>-->
                  <div
                    v-for="(step,step_index) in section.steps"
                    :key="step.id || step.title"
                    style="padding-left:15px; margin-top: 80px;"
                  >
                    <label class="form-control-label">Step:</label>
                    <label class="form-control-label">{{sec_index+1 + "." + (step_index+1)}}</label>
                    <span v-if="step.notApplicable" style="color: red; margin-left: 20px">Not Applicable</span>
                    <div style="float: right; padding-left: 15px;">
                      <label for="checkbox" v-if="step.notApplicable" style="font-size: 14px; color: red;">NA</label>
                      <label for="checkbox" v-else style="font-size: 14px; color: rgb(45, 206, 137);">NA</label>
                      <input type="checkbox" id="checkbox" style="margin-left: 5px;" v-model="step.notApplicable" :disabled="step.notApplicable" @click="notApplicableStep($event,step,step_index)"/> 
                      <span class="user-audit" v-if="step.notApplicable">{{step.notApplicableBy + " - on " + formatDate(step.notApplicableTime) }}</span><br>
                    </div>
                    <div
                      :id="step.uid"
                      contenteditable="true"
                      class="ckeditor-x form-control-alternative document-step"
                      @focus="registerEditor($event)"
                      v-if="step.uiEditable"
                      v-on:click="handleOverlaysEdit"
                      v-html="step.description"
                    ></div>
                    <!-- Section Readonly -->
                    <div
                      v-if="step.description && !step.uiEditable"
                      v-html="step.description"
                      class="form-control-alternative document-step"
                    ></div>
                    <!-- <div
                      v-if="step.description"
                      v-html="step.description"
                      class="form-control-alternative"
                    >
                    </div> -->
                    <div class="row" style="float: right; padding-right: 10px;">
                      <div
                        class="step-action-btn-group step-gap-btn"
                        style="float: right; padding-left: 2px;  margin-left: 5px; color: #2dce89" >
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.datatrendfound"
                          @click="DataTrend($event,step,sec_index,step_index)"
                        >Data Trend</button>
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.skillfound"
                          @click="skillTrend($event,step,sec_index,step_index)"
                        >Skill Trend</button>
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.crossreffound"
                          @click="crossref($event,step)"
                        >Cross Reference</button><br v-if="step.crossreffound && !step.videofound && !step.qrimagefound && !step.mediaimagefound && !step.formulafound && !step.timer && !step.inputfound">  
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.videofound"
                          @click="UploadVideo($event,step)"
                        >Upload Video</button><br v-if="step.videofound && !step.qrimagefound && !step.mediaimagefound && !step.formulafound && !step.timer && !step.inputfound">
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.qrimagefound"
                          @click="UploadQR($event,step)"
                        >Upload QR Code</button><br v-if="step.qrimagefound && !step.mediaimagefound && !step.formulafound && !step.timer && !step.inputfound"> 
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.mediaimagefound"
                          @click="UploadImg($event,step)"
                        >Upload Image</button><br v-if="step.mediaimagefound && !step.formulafound && !step.timer && !step.inputfound"> 
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.formulafound"
                          @click="formulaCalculate($event,step)"
                        >Calculate</button><br v-if="step.formulafound && !step.timer && !step.inputfound"> 
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.timer"
                          @click="startTimer($event,step)"
                        >Start Timer</button> 
                        <span v-if="step.timer && step.spanVisible">{{' '+ step.timermm + ' : ' + step.timerss + ' '}}</span>
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.timer"
                          @click="stopTimer($event,step)"
                        >Stop Timer</button><br v-if="step.timer && !step.inputfound">
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.inputfound"
                          @click="setValue($event,step,sec_index, step_index)"
                        >Enter Data</button><br v-if="step.inputfound">
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.location"
                          @click="setLocation($event,step)"
                        >Add Location</button>
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.viewFile"
                          @click="viewFile($event,step)"
                        >View File</button>
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.grid"
                          @click="addGrids($event,step)"
                        >Add Grid</button>
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="( isEditable() == true ) && step.description && step.table && !step.uiEditable"
                          @click="editStepDesc(step,$event)"
                        >Edit</button>
                        <button
                        class="btn-default1 btn-sm btn-step-action1"
                        v-if=" (isEditable()==true) && step.uiEditable"
                        @click="editStepDescSave(step,$event)"
                      >Done</button>
                        <button
                          class="btn-default1 btn-sm btn-step-action1"
                          v-if="step.mcq"
                          @click="selectOption($event,step)"
                        >Select Option</button><br v-if="step.mcq">
                      </div>
                      <div class="step-action-btn-group comment-btn">
                        <button
                            class="btn float-right btn-default btn-sm btn-step-action btn-comment ni ni-chat-round"
                            @click="onClickComment(step, sec_index, step_index)"
                          ><span class="commentCountBatch"> {{  step.comments ?  step.comments.length : "" }} </span> 
                        </button>
                      </div>
                      <div style="float: right; padding-left: 15px;" v-if="!step.skillfound">
                        <label for="checkbox" style="font-size: 14px; color: rgb(45, 206, 137);">Executed</label>
                        <input type="checkbox" id="checkbox" style="   margin-left: 5px;" v-model="step.executed" :disabled="step.executed" @click="executeStep($event,step)"/> 
                        <span class="user-audit" v-if="step.executed">{{step.executedBy + " - on " + formatDate(step.executedTime) }}</span><br>
                        <label for="checkbox" style="margin-left: 5px; font-size: 14px; color: rgb(45, 206, 137);" >Verified</label>
                        <input type="checkbox" id="checkbox" style="    margin-left: 5px;" v-model="step.approved" :disabled="step.approved" @click="approveStep($event, step)"/>
                        <span class="user-audit" v-if="step.approved">{{step.approvedBy + " - on " + formatDate(step.approvedTime) }}</span><br>
                      </div>
                      <div v-if="step.skillfound" style="float: right; padding-left: 15px;">
                        <label for="checkbox" style="font-size: 14px; color: rgb(45, 206, 137);">Executed</label>
                        <input type="checkbox" id="checkbox" style="   margin-left: 5px;" v-model="step.executed"  :disabled="step.executed" @click="onChangeExecute($event,step,sec_index,step_index)"/> 
                        <span class="user-audit" v-if="step.executed">{{step.executedBy + " - on " + formatDate(step.executedTime) }}</span><br>
                        <label for="checkbox" style="margin-left: 5px; font-size: 14px; color: rgb(45, 206, 137);" >Verified</label>
                        <input type="checkbox" id="checkbox" style="    margin-left: 5px;" v-model="step.approved"  :disabled="step.approved" @click="onChangeApprove($event,step,sec_index,step_index)"/>
                        <span class="user-audit" v-if="step.approved">{{step.approvedBy + " - on " + formatDate(step.approvedTime) }}</span><br>
                      </div>
                    </div>
                    <!-- <div
                      v-for="(step2, step_inner_index) in step.steps"
                      :key="step2.id || step2.title"
                      style="padding-left:30px ;"
                    >
                      <label class="form-control-label">Step Inner:</label>
                      <label
                        class="form-control-label"
                      >{{sec_index+1 + "." + (step_index+1) +"." + (step_inner_index+1) }}</label>
                      <div
                        v-if="step.description"
                        v-html="step.description"
                        class="form-control-alternative"
                      ></div>
                      <label class="form-control-label">Add Step Inner:</label>
                      <div
                        :id="getId()"
                        contenteditable="true"
                        class="ckeditor-x form-control-alternative"
                        @focus="registerEditor($event)"
                      ></div>
                      <button
                        class="btn float-right btn-default btn-sm"
                        @click="addStepInner(step, $event)"
                      >Add Step Inner</button>
                    </div>-->
                  </div>
                  <!-- <div v-if="section">
                    <label class="form-control-label">Add Step:</label>
                    <div
                      :id="getId()"
                      contenteditable="true"
                      class="ckeditor-x form-control-alternative"
                      @focus="registerEditor($event)"
                    ></div>
                    <button
                      class="btn float-right btn-default btn-sm"
                      @click="addStep(section, $event)"
                    >Add Step</button>
                  </div>-->
                </div>
              </div>
              <!-- <div v-if="documentTemp.title">
                <label class="form-control-label">Add Section:</label>
                <div
                  :id="getId()"
                  contenteditable="true"
                  class="ckeditor-x form-control-alternative"
                  @focus="registerEditor($event)"
                ></div>
                <button
                  class="btn float-right btn-default btn-sm"
                  @click="addSection($event)"
                >Add Section</button>
              </div>-->
            </div>
            <!-- <button class="btn float-right btn-default btn-sm" @click="saveDocument()">Save Document</button> -->
          </div>
        </div>
        <div class="col editRightParent" v-if="this.documentTemp.status1 != 'archived'">
          <div id="editorControlList">
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                @click="saveDocument()"
              >SAVE</button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
           <!--    <a href="#!" class="btn btn-info">REVIEW</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
           <!--    <a href="#!" class="btn btn-info">COMMENT</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
            <a href="#!" class="btn btn-info">SHARE</a> 
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
            <!--   <a href="#!" class="btn btn-info">CHECK</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <a class="btn btn-info" @click="archiveBatch($event)">FINISH</a>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
           <!--    <a href="#!" class="btn btn-info">DELETE</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {{this.documentTemp}} -->
  <!-- Begin: Image Upload Batch Document -->
    <div v-if="showImageBatchModal">
      <transition name="modal">
        <div class="modal-mask-Batch">
          <div class="modal-wrapper-Batch">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload Image" }}
                  <button
                    type="button"
                    class="close"
                    @click="showImageBatchModal=false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload Image {{ImageVarName}}:</span>
                  <input type="file" id="file" style="margin-left: 36px; margin-bottom: 10px;" ref="file" name="file-to-upload" v-on:change="handleFileUploads()">
                    <base-button
                    size="sm"
                    type="default"
                    style="margin-bottom: 10px;"
                    class="float-right1"
                    @click="addImage($event)"
                    >Add Image</base-button>
                    <p v-if="filespath"><img style="width:100px; height:100px; margin-bottom: 10px;" :src="filespath" alt=""></p>
                    <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="ReplaceImg('Image')"
                  >Upload</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showImageBatchModal=false"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  <!-- End: Image Upload Document -->

  <!-- Begin: QR Image Upload Batch Document -->
    <div v-if="showQRImageBatchModal">
      <transition name="modal">
        <div class="modal-mask-Batch">
          <div class="modal-wrapper-Batch">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload QR Code" }}
                  <button
                    type="button"
                    class="close"
                    @click="showQRImageBatchModal=false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload QR Code {{QRImageVarName}}:</span>
                    <p class="error">{{ error }}</p>
                    <qrcode-stream style="width:70%" :camera="camera" @decode="onDecode" @init="onInit"></qrcode-stream>
                    <p class="decode-result">Result: <b>{{ result }}</b></p>
                    <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="ReplaceImg('QR')"
                  >Upload</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showQRImageBatchModal=false"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  <!-- End: QR Image Upload Document -->

   <!-- Begin: Video Image Upload Batch Document -->
    <div v-if="showVideoBatchModal">
      <transition name="modal">
        <div class="modal-mask-Batch">
          <div class="modal-wrapper-Batch">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload Video" }}
                  <button
                    type="button"
                    class="close"
                    @click="showVideoBatchModal=false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload Video {{VideoVarName}}:</span>
                  <input type="file" id="filevideo" style="margin-left: 36px; margin-bottom: 10px;" ref="filevideo" name="file-to-upload" v-on:change="handleFileVideoUploads()">
                  <base-button
                  size="sm"
                  type="default"
                  style="margin-bottom: 10px;"
                  class="float-right1"
                  @click="addVideo($event)"
                >Add Video</base-button>
                
                <p v-if="videopath">
                  <video width="320" height="240" controls>
                    <source :src="videopath" :type="videotype">
                    Your browser does not support the video tag.
                  </video></p>
                    
                    <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="ReplaceImg('VIDEO')"
                  >Upload</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showVideoBatchModal=false"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  <!-- End: Video Image Upload Document -->
    <div class="overlay-modal" v-if="showDataTredBatchModal" @click="planetData()" style="text-align: center">
      <button class="btn-default1 btn-sm btn-step-action1">View Graph</button>
      <canvas id="planet-chart"></canvas>
    <!-- </div> -->
<!-- Begin: Data Tred Batch Document -->
      <div v-if="showDataTredBatchModal">
        <transition name="modal">
          <div class="modal-mask-Batch">
            <div class="modal-wrapper-Batch">
              <div class="modal-dialog share-modal">
                <div class="modal-content">
                  <div class="modal-header">
                    {{ "Data Trending" }}
                    <button
                      type="button"
                      class="close"
                      @click="showDataTredBatchModal=false">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <span>Data Trending</span>
                      
                    <div class="table-responsive">
                        <base-table
                          class="table align-items-center table-flush"
                          
                          tbody-classes="list"
                          :data="documentdataTred"
                        >
                          <template slot="columns">
                            <th>Batch No</th>
                            <th>Variable Name</th>
                            <th>Value</th>
                          </template>

                          <template slot-scope="{row}">
                            <td>
                              <div class="avatar-group">
                                <span class="status">{{row.batchno}}</span>
                              </div>
                            </td>
                            <td>
                              <div class="avatar-group">
                                <span class="status">{{row.varname}}</span>
                              </div>
                            </td>
                            <td>
                              <div class="avatar-group">
                                <span class="status">{{row.varvalue}}</span>
                              </div>
                            </td>
                          </template>
                        </base-table>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  <!-- End: Data Tred Document -->
  
    <div class="overlay-modal graph-button" v-if="showSkillTredBatchModal" style="text-align: center">
      <button class="btn-default1 btn-sm btn-step-action1" @click="skillData()">Executed Graph</button>
      <button class="btn-default1 btn-sm btn-step-action1" @click="verifiedSkillData()">Verified Graph</button>
      <canvas id="planet-chart"></canvas>
    
    <!-- <div class="overlay-modal graph-button" v-if="showSkillTredBatchModal" @click="verifiedSkillData()" style="text-align: center">
      <button class="btn-default1 btn-sm btn-step-action1" style="position: absolute;">Verified Graph</button>
      <canvas id="planet-chart"></canvas> -->
    

    <div v-if="showSkillTredBatchModal">
        <transition name="modal">
          <div class="modal-mask-Batch">
            <div class="modal-wrapper-Batch">
              <div class="modal-dialog share-modal">
                <div class="modal-content">
                  <div class="modal-header">
                    {{ "Skill Trending" }}
                    <button
                      type="button"
                      class="close"
                      @click="showSkillTredBatchModal=false">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <span>Skill Trending</span>
                      
                    <div class="table-responsive">
                        <base-table
                          class="table align-items-center table-flush"
                          tbody-classes="list"
                          :data="documentSkill"
                        >
                          <template slot="columns">
                            <th>Batch No</th>
                            <th>Executed User</th>
                            <th>Verified User</th>
                          </template>
                          <template slot-scope="{row}">
                            <td>
                              <div class="avatar-group">
                                <span class="status">{{row.batchno}}</span>
                              </div>
                            </td>
                          <td>
                              <div class="avatar-group" v-for="(executeduser, executeduser_index) in row.executedUsers" :key="executeduser_index">
                                <span class="status">{{executeduser.executeduser}}</span>
                              </div>
                            </td>
                            <td>
                              <div class="avatar-group" v-for="(verifieduser, verifieduser_index) in row.verifiedUsers" :key="verifieduser_index">
                                <span class="status">{{verifieduser.verifieduser}}</span>
                              </div>
                            </td>
                          </template>
                        </base-table>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
    </div>
    </div>
    <!-- </div> -->
  
  <!-- <div v-if="showCanvas">
    <canvas id="planet-chart"></canvas>
  </div> -->
  <!-- start viewlist modal -->
    <div v-if="showViewListBatchModal">
        <transition name="modal">
          <div class="modal-mask-Batch view-data">
            <div class="modal-wrapper-Batch">
              <div class="modal-dialog share-modal">
                <div class="modal-content">
                  <div class="modal-header">
                    {{ "View list of data-inputs and formula" }}
                    <button
                      type="button"
                      class="close"
                      @click="showViewListBatchModal=false">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="table-responsive">
                        <base-table
                          class="table align-items-center table-flush formula-datainput-table-list"
                          id="batch-table"
                          tbody-classes="list"
                          :data="documentviewList"
                        >
                          <template slot="columns">
                            <th>Step No</th>
                            <th>Data Label</th>
                            <th>Data</th>
                          </template>
                          <template slot-scope="{row}" v-if="row.type">
                            <td> {{row.stepno}} </td>
                            <td style="white-space:pre-line;"> {{row.datalabel}} </td>
                            <td style="white-space:pre-line;"> {{row.data}} </td>
                          </template>
                        </base-table>
                    </div>
                    <!-- <i class="fa fa-download"></i> -->
                    <button @click="tableToCSV()"
                    class="btn float-right btn-default btn-sm m-2">
                    Export as CSV</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showViewListBatchModal=false"
                  >Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
    </div>
  <!-- end viewlist modal -->
<!-- Begin: Cross Reference Batch Document -->
    <div v-if="showCrossRefBatchModal">
      <transition name="modal">
        <div class="modal-mask-Batch">
          <div class="modal-wrapper-Batch">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Cross Reference" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCrossRefBatchModal=false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <label
                    class="form-control-label"
                    v-html="documentcrossRef"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  <!-- End: Cross Reference Document -->

  <div v-if="showCommentModal">
      <transition name="modal">
        <div class="modal-mask-Batch">
          <div class="modal-wrapper-Batch">
            <div class="modal-dialog comment-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Add Comment for Section : '" + commentTitle + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCommentModal=false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Comments display-->
                <div>
                  <div class="sec-toggle comment-section" @click="sectionToggleComment">
                    <label
                      class="form-control-label comment-header ctrl-collapse"
                      style="display:none;width: fit-content;"
                      @click="sectionToggleComment"
                    >+ Comments</label>
                    <label
                      class="form-control-label comment-header ctrl-expand"
                      style="width: fit-content;"
                      @click="sectionToggleComment"
                    >- Comments</label>
                  </div>
                  <div class="sec-body">
                    <div
                      v-for="(comment, comment_index) in this.currentStep.comments"
                      :key="comment.id || comment_index"
                      style="padding-left:15px ;"
                      class="comment-text"
                    >
                      <span class="comment-user">{{comment.createdBy}}</span>
                      {{ comment.description + " - on " + formatDate(comment.createdTime)}}
                    </div>
                  </div>
                </div>

                <div class="modal-body">
                  <span>Comments</span>
                  <textarea
                    v-model="commentDescription"
                    placeholder="Enter your comment"
                    style="width:100%"
                  ></textarea>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="addComment()"
                  >Add Comment</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showCommentModal = false"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
  </div>

  <!---- Begin google map location -->
  <div v-if="showGoogleMapModal">
      <transition name="modal">
        <div class="modal-mask-Batch">
          <div class="modal-wrapper-Batch">
            <div class="modal-dialog comment-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Add Live Location Bookmark by Google Map" }}
                  <button
                    type="button"
                    class="close"
                    @click="showGoogleMapModal=false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <add-google-map></add-google-map>
                  <label for="address">Add Location Details: </label>
                  <input type="text" id="address" name="location" v-model="text"
                  style="width: 80%; margin-left:10px; margin-top: 20px;">
                  <!-- <form id="form">
                    <div class="form-group container">
                      <label for="address">Enter Address: (Autocomplete)</label>
                      <input type="text" class="form-control" placeholder="Enter your address" required id="searchaddress"/>
                    </div>
                  </form> -->
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="ReplaceLocation($event)"
                  >Add Location</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showGoogleMapModal=false"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
  </div>
  <!--- End google map location -->

    <div v-if="showGridModal">
        <transition name="modal">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog modal-table">
                <div class="modal-content">
                  <div class="modal-header">
                    Grid Title: Client Info
                    <button
                      type="button"
                      class="close"
                      @click="showGridModal = false"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div slot="header">
                      <button class="btn btn-secondary" @click="addItem">Add an item dynamically</button>
                      <input type="checkbox" v-model="draggable" /> Draggable
                      <input type="checkbox" v-model="resizable" /> Resizable
                    </div>
                    <grid-layout :layout.sync="layout"
                      :col-num="colNum"
                      :row-height="30"
                      :is-draggable="draggable"
                      :is-resizable="resizable"
                      :vertical-compact="true"
                      :use-css-transforms="true"
                    >
                    <grid-item v-for="item in layout"
                        :static="item.static"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        :key="item.i"
                    >
                      <span class="remove" @click="removeItem(item.i)">x</span>
                    <textarea
                      id="message"
                      style="width: 90%"
                      name="message"
                      v-model="item.textarea"
                      placeholder="Enter text here..."
                    ></textarea>
                    </grid-item>
                    </grid-layout>
                    <br />
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="submitGrid()"
                    >
                      Submit
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="showGridModal = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
    </div>
  
  </div>
</template>
<script>
/* eslint-disable no-console */

import axios from "../../../utils/axiosinstance";

import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import documentHeader from "@/views/process/documentsv1/documentHeader";
import { QrcodeStream } from "vue-qrcode-reader";
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD9HupIM7z4PPAuRCnY53LKZpbQnJ6Q87Q",
    libraries: "places"
  }
});
import AddGoogleMap from "../../../views/AddGoogleMap.vue";
import Chart from 'chart.js'
import { GridLayout, GridItem } from "vue-grid-layout";

export default {
  components: { documentHeader, QrcodeStream, AddGoogleMap, GridLayout, GridItem },
  // props: ["batchID"],
  data() {
    return {
      templateType: "document",
      docStatusType: "",
      batchID: "",
      textarea: '',
      layout: [{ x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), static: false },],
      stepGrid: [],
      draggable: true,
      resizable: true,
      colNum: 12,
      index: 0,
      showGridModal: false,
      formulaResultValue: '',
      graphsecIndex: '',
      graphstepIndex: '',
      execount: 0,
      verifycount: 0,
      skillCount: '',
      text: '',
      inputValue: '',
      showGoogleMapModal: false,
      showCanvas: false,
      currentStep: '',
      showCommentModal: false,
      newComments : [],
      documentTemp: {},
      documentdataTred: [],
      documentviewList: [],
      documentSkill: [],
      documentcrossRef: '',
      temptag: [],
      tagjson:{},
      timermm: "00",
      timerss: "00",
      timerStart: false,
      spanVisible: false,
      oldstring: [],
      showImageBatchModal: false,
      showDataTredBatchModal: false,
      showSkillTredBatchModal: false,
      showViewListBatchModal: false,
      showCrossRefBatchModal: false,
      VideoVarName: '',
      videopath: '',
      Mappath: '',
      videotype: '',
      videopathold: '',
      videotypeold: '',
      showVideoBatchModal: false,
      ImageVarName: '',
      ImageSrc: '',
      showQRImageBatchModal: false,
      QRImageVarName: '',
      QRImageSrc: '',
      result: '',
      oldString: 'Upload QR Code',
      error: '',
      camera: 'auto',
      files: '', 
      filespath: '',
      tempstep: null,
      authCookie: null,
    };
  },
  beforeMount() {
    this.batchID = this.$route.query.batchID;
    this.docStatusType = this.$route.query.docStatusType;
    this.templateType = this.$route.query.templateType;
  },
  mounted() {
    console.dir(this.batchID);
    if (this.batchID) {
      axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/batches/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          this.documentTemp = response.data.item;
          this.documentTemp.title = this.documentTemp.title.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]/g, '');
          this.usagelogtagTemp = {};
          
          //steplock
          this.steplock();

        })
        .catch(reason => {
          console.log(reason);
          this.documentTemp = [];
          this.usagelogtagTemp = {};
        });
    }
    this.index = this.layout.length;
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    // this.authCookie = JSON.parse(authCookieObj.substring(2));
    let authObj;
    if (authCookieObj.startsWith("j:")) {
      authObj = JSON.parse(
        decodeURIComponent(
          this.getCookie("auth-token")
            .substring(2)
            .split(";")[0]
        )
      );
    } else {
      authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
    }
    this.authCookie = authObj;
  },
  beforeCreate() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "ckeditor.js");
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    isEditable() {
      // console.log(this.docType);
      return (
        !this.docType ||
        ["approved", "library", "shared", "archived", "template", "batch"].indexOf(
          this.docType.toLowerCase()
        ) < 0
      );
    },
    editStepDesc(step) {
      this.$set(step, "uiEditable", true);
    },
    editStepDescSave(step, e) {
      var el = e.target.parentElement.parentElement.parentElement.querySelector(".ckeditor-x");
      var editor = this.getEditorInstance(el);
      if (editor){
        this.$set(step, "description", editor.getData());
        console.dir(editor.getData());
      } else {
        this.$set(step, "description", el.innerHTML);
      }
      this.$set(step, "uiEditable", false);
    },
    addItem: function () {
        // Add a new item. It must have a unique key!
        this.layout.push({
            x: (this.layout.length * 2) % (this.colNum || 12),
            y: this.layout.length + (this.colNum || 12), // puts it at the bottom
            w: 2,
            h: 2,
            i: this.index.toString(),
            uid: uuidv4(),
        });
        // Increment the counter to ensure key is always unique.
        this.index++;
    },
    removeItem: function (val) {
        const index = this.layout.map(item => item.i).indexOf(val);
        this.layout.splice(index, 1);
    },
    submitGrid(e) {
      console.log(e);
      for(var i=0;i<this.documentTemp.sections.length;i++){
      for(var j=0;j<this.documentTemp.sections[i].steps.length;j++)
      {
          var dom = document.createElement('div');
          dom.innerHTML = this.documentTemp.sections[i].steps[j].description;
          if(this.documentTemp.sections[i].steps[j].grid){
          this.inputValue = this.text;
          for(var k=0;k<this.layout.length;k++){
          this.stepGrid = this.layout[k];
          // this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(this.videopathold,this.videopath);
          this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().concat('<span> '+this.inputValue+' </span>').concat('<textarea id="Textarea" name="message" style="width: 10%;">'+ this.stepGrid.textarea +'</textarea>')
        }
        }
      }
    }
    this.showGridModal = false;
	this.layout = [{ x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), static: false },];
	},
  tableToCSV(){
    let csv_data = [];
    let rows = document.getElementById('batch-table');
        let cols = rows.querySelectorAll('tr');
        let csvrows = [];
        for (let j = 0; j < cols.length; j++) {
          let csvrow = [];
            if (j==0) {
              let headers = cols[j].querySelectorAll('th');
              for (let k = 0; k < headers.length; k++) {
                csvrow.push(headers[k].innerHTML);
              }
            } else {
              let data = cols[j].querySelectorAll('td');
                for (let k = 0; k < data.length; k++) {
                  if (data[k].innerHTML && data[k].innerHTML.length > 0) {
                  let replaceText = data[k].innerHTML.replace(/\n/g, "  ");
                  csvrow.push(replaceText);
                  continue;
                  }
                csvrow.push(data[k].innerHTML);
              }
            }
          csvrows.push(csvrow.join(","));
        }
        //csv_data.push(csvrows);
    // }
    csv_data = csvrows.join('\n');
    this.downloadCSVFile(csv_data);
  },
  downloadCSVFile(csv_data) {
    var CSVFile = {};
    CSVFile = new Blob([csv_data], {
        type: "text/csv"
    });
      let temp_link = document.createElement('a');
    temp_link.download = "GfG.csv";
    let url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    temp_link.click();
    document.body.removeChild(temp_link);
  },
    // handleInput(event) {
    //   console.log(event.target.value);
    // },
    // embedLocation(){
    //   var lat,long,address
    //       address = document.getElementById('searchaddress')
    //       AddGoogleMap.events.addDomListener(window, "load", () => {
    //       var places = new AddGoogleMap.places.Autocomplete(address)
    //       console.log(places)
    //       AddGoogleMap.event.addListener(places, "place_changed", () => {
    //       console.log(places.getPlace())
    //       var place = places.getPlace()
    //       lat = place.geometry.location.lat()
    //       long = place.geometry.location.long()
    //       console.log(lat)
    //       console.log(long)
    //       console.log(lat)
    //       console.log(long)
    //       address = `
    //       <iframe width="500" height="5000" src="https://maps.google.com/maps?q=${lat},${long}&hl=es;z=14&output=embed"></iframe>
    //       `
    //       console.log(address);
    //       })
    //   })
    // },
    ReplaceLocation(event){
      console.log(event);
      for(var i=0;i<this.documentTemp.sections.length;i++){
      for(var j=0;j<this.documentTemp.sections[i].steps.length;j++)
      {
          var dom = document.createElement('div');
          dom.innerHTML = this.documentTemp.sections[i].steps[j].description;
          if(this.documentTemp.sections[i].steps[j].location){
          this.inputValue = this.text;
          // var newScript = document.createElement("script");      
          // newScript.setAttribute('src', "https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyD9HupIM7z4PPAuRCnY53LKZpbQnJ6Q87Q");
          this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().concat('<span> '+this.inputValue+' </span>').
          concat('<iframe src="https://maps.google.com/maps?q=39.7837304, -100.4458825&z=15&output=embed" width="360" height="270" frameborder="0" style="border:0"></iframe>')
        }
      }

    }
    this.showGoogleMapModal = false;
    },
    setLocation($event, step){
      console.log(event);
      console.log(step);
      this.showGoogleMapModal = true;
    },
    addGrids($event, step){
      console.log(event);
      console.log(step);
      this.showGridModal = true;
    },
    selectOption($event, step){
      console.log(event);
      var orginalStringOption = step.description;
      var selectedStringOption = orginalStringOption.replace(/(<([^>]+)>)/gi,"").trim();
      console.log(selectedStringOption);
      // var pValText = prompt(" Enter selected options from these "  + selectedStringOption, "" );
      var pValText = prompt(" Enter selected option number only", "" );
      if (!pValText) return;
      if(step.description && step.description.includes("Option Selected:") ){
        step.description +=  '<b>' + pValText  +  "  "  + '</b>';
      }  
      else{
        step.description +=  "Option Selected:" + '<b>' + pValText  +  "  "  + '</b>';
      }
    },
    planetData(){
      // console.log(documentTemp);
      axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/batches/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var planetTemp = response.data.item;
          for(let i = 0; i < planetTemp.sections.length; i++){
            for(let j = 0; j < planetTemp.sections[i].steps.length; j++){
              for(let k = 0; k < planetTemp.sections[i].steps[j].dataTrandings.length; k++){
                var variableBatchno = planetTemp.sections[i].steps[j].dataTrandings[k].batchno;
                var variableName = planetTemp.sections[i].steps[j].dataTrandings[k].varname;
                var variableValue = planetTemp.sections[i].steps[j].dataTrandings[k].varvalue;
                console.log(variableValue);
                this.showCanvas = true;
                    const ctx = document.getElementById('planet-chart');
                    new Chart(ctx, {
                    type: "line",
                    data: {
                      labels: [variableBatchno],
                      datasets: [
                        {
                          label: variableName,
                          data: [variableBatchno],            
                          borderColor: "#36495d",
                          borderWidth: 2,
                        },
                        
                      ]
                    },
                    options: {
                      responsive: true,
                      lineTension: 1,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 25,
                              AutoRange: true,
                              // fontSize: 40,
                              // max: 100,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Values ------>'
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              padding: 25,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Batch Number ------>'
                            }
                          }
                        ]
                      }
                    }
                    });
                }
              }
            }
        })
        .catch(reason => {
          console.log(reason);
          this.documentTemp = [];
        });
    },
    skillData(){
      axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var skillTemp = response.data.items;
          const  tempExecuteObj = new Map();
          for(let i = 0; i < skillTemp.length; i++){
            for(let j = 0; j < skillTemp[i].sections[this.graphsecIndex].steps[this.graphstepIndex].skills.length; j++){
              // for(let k = 0; k < skillTemp[i].sections[j].steps.length; k++){
                // for(let l = 0; l < skillTemp[i].sections[j].steps[k].skills.length; l++){
                var executeduser = skillTemp[i].sections[this.graphsecIndex].steps[this.graphstepIndex].skills[j].executedUsers[0].executeduser;
                
                if(tempExecuteObj.get(executeduser)) {
                  let count = tempExecuteObj.get(executeduser);
                  tempExecuteObj.set(executeduser, ++count);
                } else {
                  tempExecuteObj.set(executeduser, 1);
                }                
              }
                // }
              // }
            }
                    this.showCanvas = true;
                    const ctx = document.getElementById('planet-chart');
                    new Chart(ctx, {
                    type: "bar",
                    data: {
                      labels: Array.from(tempExecuteObj.keys()),
                      datasets: [
                        {
                          label: "executedUser",
                          data: Array.from(tempExecuteObj.values()),         
                          borderColor: "#36495d",
                          borderWidth: 1,
                        },
                        
                      ]
                    },
                    options: {
                      responsive: true,
                      lineTension: 1,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 25,
                              AutoRange: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Counts ------>'
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              padding: 25,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'User Names ------>'
                            }
                          }
                        ]
                      }
                    }
                    });
        })
        .catch(reason => {
          console.log(reason);
          this.documentTemp = [];
        });
    },
    verifiedSkillData(){
      axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var skillTemp = response.data.items;
          const  tempVerifyObj = new Map();
          for(let i = 0; i < skillTemp.length; i++){
            for(let j = 0; j < skillTemp[i].sections[this.graphsecIndex].steps[this.graphstepIndex].skills.length; j++){
                var verifieduser = skillTemp[i].sections[this.graphsecIndex].steps[this.graphstepIndex].skills[j].verifiedUsers[0].verifieduser;
                if(tempVerifyObj.get(verifieduser)) {
                  let count = tempVerifyObj.get(verifieduser);
                  tempVerifyObj.set(verifieduser, ++count);
                } else {
                  tempVerifyObj.set(verifieduser, 1);
                }                
              }
            }
                    this.showCanvas = true;
                    const ctx = document.getElementById('planet-chart');
                    new Chart(ctx, {
                    type: "bar",
                    data: {
                      labels: Array.from(tempVerifyObj.keys()),
                      datasets: [
                        {
                          label: "verifieduser",
                          data: Array.from(tempVerifyObj.values()),         
                          borderColor: "#36495d",
                          borderWidth: 1,
                        },
                        
                      ]
                    },
                    options: {
                      responsive: true,
                      lineTension: 1,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 25,
                              AutoRange: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Counts ------>'
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              padding: 25,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'User Names ------>'
                            }
                          }
                        ]
                      }
                    }
                    });
        })
        .catch(reason => {
          console.log(reason);
          this.documentTemp = [];
        });
    },
    addComment() {
      if (!this.currentStep.comments) this.currentStep.comments = [];
      var comment = {};
      comment.description = this.commentDescription;
      comment.createdBy = this.authCookie.email;
      comment.createdTime = new Date();
      this.currentStep.comments.push(comment);
      this.newComments.push(comment);
      this.notifyUserComment(this.documentTemp);
      this.showCommentModal = false;
      this.showCommentModal = true;
    },
    sectionToggleComment(e) {
      let parentElement = e.target.parentElement;
      if (e.classList && e.classList.contains("sec-toggle")) {
        parentElement = e.parentElement;
      }
      var secBody = parentElement.querySelector(".sec-body");
      if (secBody.style.display && secBody.style.display == "none") {
        secBody.style.display = "block";
        parentElement.querySelector(".ctrl-collapse").style.display = "none";
        parentElement.querySelector(".ctrl-expand").style.display = "block";
      } else {
        secBody.style.display = "none";
        parentElement.querySelector(".ctrl-collapse").style.display = "block";
        parentElement.querySelector(".ctrl-expand").style.display = "none";
      }
    },
    onClickComment(step, secIndex, stepIndex) {
      if(  !(this.authCookie && this.authCookie.userName) )
      { 
        this.$emit("showLoginOnShared", this.docID);
        return;
      }
      this.currentStep = step;
      this.commentTitle = secIndex + 1 + "." + (stepIndex + 1);
      this.commentSection = secIndex + 1;
      this.showCommentModal = true;
      this.commentDescription = "";
    },
    async onDecode (content) {
      this.result = content;
      this.turnCameraOff();
      await this.timeout(3000);
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    turnCameraOff () {
      this.camera = 'off'
    },
    turnCameraOn () {
      this.camera = 'auto'
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        }
      }
},
    steplock(){
    //steplock
          var steplockfound = false;
          var executedstap = false;
          var stepdeti;
          var stepdetj;
          var disarray = [];
          var i = 0;
          var totalsec = this.documentTemp.sections;
          for(i=0;i<totalsec.length;i++)
          {
            var j = 0;
            var eachstep = totalsec[i].steps
            for(j=0;j<eachstep.length;j++)
            {
                //var v1 = eachstep[j].description.toString();
                var dom = document.createElement('div');
                dom.innerHTML = eachstep[j].description;
                var imgtag = dom.getElementsByTagName('img');
                  if(imgtag != undefined && imgtag.length > 0)
                  {
                    var ki;
                    for(ki=0;ki<imgtag.length;ki++)
                    {
                      //console.log(imgtag[ki]);
                      var imgtagalt = imgtag[ki].getAttribute('alt');
                      //console.log(imgtagalt);
                      if(imgtagalt.toString() == "StepLock")
                      {
                        steplockfound = true;
                        stepdeti = i;
                        stepdetj = j;
                        if(eachstep[j].executed != undefined && eachstep[j].executed == true)
                        {
                          executedstap = true;
                        }
                        //console.log((i+1) +'.'+ (j+1));
                        //console.log(steplockfound);
                      }
                    }
                  }
                if(steplockfound == true)
                {
                    if(i == stepdeti)
                    {
                      if(j > stepdetj)
                      {
                        //console.log('desable');
                        //console.log((i+1) +'.'+ (j+1));
                        disarray.push(((i+1) +'.'+ (j+1)));
                      }
                    }
                    else if(i > stepdeti)
                    {
                      //console.log('desable');
                      //console.log((i+1) +'.'+ (j+1));
                      disarray.push(((i+1) +'.'+ (j+1)));

                    }
                }
                    
            }
          }

          //find div
          //console.log('disarray');
          //console.log(disarray);
          if(disarray.length > 0)
          {
            setTimeout(function() {
              var cuslabel = document.querySelectorAll('label.form-control-label');
              //console.log(cuslabel);
              var li;
              for(li=0;li<cuslabel.length;li++)
              {
                var ji;
                for(ji=0;ji<disarray.length;ji++)
                {
                  if(cuslabel[li].innerHTML.toString() == disarray[ji].toString())
                  {
                    //console.log(cuslabel[li].innerHTML.toString());
                    //console.log(cuslabel[li].parentElement.setAttribute("class","custom-disable"));
                    if(executedstap == true)
                    {
                      if(cuslabel[li].parentElement.getAttribute("class") != undefined)
                      {
                        if(cuslabel[li].parentElement.getAttribute("class").toString() == "custom-disable")
                        {
                          cuslabel[li].parentElement.removeAttribute("class");
                        }
                      }
                      
                    }
                    else
                    {
                      cuslabel[li].parentElement.setAttribute("class","custom-disable");
                    }
                    
                  }
                }
              }
            }, 100);
          }
  },
  ReplaceImg(type)
  {
    var i;
    for(i=0;i<this.documentTemp.sections.length;i++)
    {
      var j;
      for(j=0;j<this.documentTemp.sections[i].steps.length;j++)
      {
        if(this.tempstep.uid.toString() == this.documentTemp.sections[i].steps[j].uid.toString())
        {
          //console.log('Replace Fun');
          //console.log(this.documentTemp.sections[i].steps[j].description);
          var dom = document.createElement('div');
          dom.innerHTML = this.documentTemp.sections[i].steps[j].description;
          if(type == "VIDEO")
          {
            var atag = dom.getElementsByTagName('a');
            if(atag != undefined && atag.length > 0)
            {
              //var ki;
              for(ki=0;ki<atag.length;ki++)
              {
                //console.log(atag[ki].innerHTML);
                var oldstr = atag[ki].innerHTML;
                altarray = atag[ki].innerHTML.toString().split('Video: ');
                if(altarray != undefined && altarray.length > 0)
                {
                  if(altarray[0] == "Upload ")
                  {
                    var vtag = dom.getElementsByTagName('video');
                    if(vtag != undefined && vtag.length > 0)
                    {
                      if(this.videopathold != '' && this.videotypeold != '')
                      {
                        this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(this.videopathold,this.videopath);
                        this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(this.videotypeold,this.videotype);
                        this.videopathold = this.videopath; 
                        this.videotypeold = this.videotype;
                      }
                    }
                    else
                    {
                    atag[ki].insertAdjacentHTML('beforeend', '<br> <video id="'+ altarray[1] +'" width="320" height="240" controls><source src="'+ this.videopath +'" type="'+ this.videotype +'">Your browser does not support the video tag.</video>');
                    //console.log('video tag added');
                    //console.log(atag[ki].innerHTML);
                    this.videopathold = this.videopath; 
                    this.videotypeold = this.videotype;
                    this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(oldstr,atag[ki].innerHTML.toString());
                    }
                  }          
                }
              }
            }
          }
          else
          {  
            var imgtag = dom.getElementsByTagName('img');
            if(imgtag != undefined && imgtag.length > 0)
            {
              var ki;
              for(ki=0;ki<imgtag.length;ki++)
              {
                var imgtagalt = imgtag[ki].getAttribute('alt');
                var imgtagsrc = imgtag[ki].getAttribute('src');
                var altarray = imgtagalt.split('_');
                var varName = null;
                if(altarray != undefined && altarray.length > 0)
                {
                  if(altarray[0] == "mediaImage" && type == "Image")
                  {
                    varName = altarray[1];
                    if (varName == this.ImageVarName && this.filespath != '')
                    {
                      this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace("Upload Image","");   
                        imgtag[ki].setAttribute('src',this.filespath);
                        this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(imgtagsrc,this.filespath); 
                    }
                  }
                  //console.log("QR");
                  //console.log(altarray[0]);
                  if(altarray[0] == "qrImage" && type == "QR")
                  {
                    varName = altarray[1];
                    if (varName == this.QRImageVarName && this.result != '')
                    {
                      this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(this.oldString,this.result);   
                        this.oldString = this.result;
                        //imgtag[ki].setAttribute('src',this.filespath);
                        //this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(imgtagsrc,this.filespath); 
                    }
                  }
                } 
              }
            }
          }
          
        }
      }
    }
    if(type == "Image")
    {
      this.ImageVarName = '';
      this.ImageSrc = '';
      this.filespath = '';
      this.showImageBatchModal = false;
    } 
    else if(type == "QR")
    {
      this.showQRImageBatchModal = false;
      this.QRImageVarName = '';
    } 
    else if(type == "VIDEO")
    {
      this.showVideoBatchModal = false;
      this.VideoVarName = '';
      this.videopath = '';
      this.videotype = '';
    }
    
  },
  crossref(e, step)
  {
    console.log(e);
    console.log(step);
    this.documentcrossRef = 'No Data Found';
    var dom = document.createElement('div');
    dom.innerHTML = step.description;
      var atag = dom.getElementsByTagName('a');
        if(atag != undefined && atag.length > 0)
        {
          var ki;
          for(ki=0;ki<atag.length;ki++)
          {
            if(atag[ki].innerHTML.toString().indexOf('ross Reference / Document Title =',0) > 0)
            {
              var altarray = atag[ki].innerHTML.toString().split('/');
              if(altarray != undefined && altarray.length > 0)
              {
                var DocTitle = altarray[1].split('=')[1].toString().trim();
                var secid = altarray[2].split('=')[1].toString().trim();
                var stepid = altarray[3].split('=')[1].toString().trim();
                console.log(DocTitle);
                secid = secid-1;
                stepid = (stepid.split('.')[1])-1;
                console.log(secid);
                console.log(stepid);
              }

              axios
              .get(
                "/api/organizations/" +
                  this.authCookie.orgs[0].orgID +
                  "/crossref/" +
                  DocTitle
              )
              .then(response => {
                this.documentcrossRef = response.data.item.sections[secid].steps[stepid].description;
                console.log(this.documentcrossRef);
                this.showCrossRefBatchModal = true;
               
              })
              .catch(reason => {
                console.log(reason);
                this.documentcrossRef = 'No Data Found';
              });
            
              

            }

          }
        }
  },
  UploadVideo(e, step)
  {
    console.log(e);
    console.log(step);
    this.tempstep = step;
    this.videopath ='';
    this.videotype = '';
    var dom = document.createElement('div');
          dom.innerHTML = step.description;
          var atag = dom.getElementsByTagName('a');
          if(atag != undefined && atag.length > 0)
          {
            var ki;
            for(ki=0;ki<atag.length;ki++)
            {
              console.log(atag[ki].innerHTML);
              var altarray = atag[ki].innerHTML.toString().split('Video: ');
              if(altarray != undefined && altarray.length > 0)
              {
                if(altarray[0] == "Upload ")
                {
                  var vtag = dom.getElementsByTagName('video');
                    if(vtag != undefined && vtag.length > 0)
                    {
                      this.VideoVarName = vtag[0].getAttribute('id');
                      this.showVideoBatchModal = true;
                    }
                    else
                    {
                      var varName = altarray[1];
                      this.VideoVarName = varName;
                      this.showVideoBatchModal = true;
                    }
                  
                }          
              }
            }
          }
  },
  UploadQR(e, step)
  {
    console.log(e);
    console.log(step);
    this.tempstep = step;
    var dom = document.createElement('div');
      dom.innerHTML = step.description;
      var imgtag = dom.getElementsByTagName('img');
        if(imgtag != undefined && imgtag.length > 0)
        {
          var ki;
          for(ki=0;ki<imgtag.length;ki++)
          {
            var imgtagalt = imgtag[ki].getAttribute('alt');
            var altarray = imgtagalt.split('_');
            var varName = null;
            if(altarray != undefined && altarray.length > 0)
            {
              if(altarray[0] == "qrImage")
              {
                varName = altarray[1];
                this.QRImageVarName = varName;
                this.QRImageSrc = imgtagsrc;
                this.showQRImageBatchModal = true;
                this.turnCameraOn();
                this.result = "";
              }
            }
            var imgtagsrc = imgtag[ki].getAttribute('src');
            //console.log(imgtagalt);
          }
        }
  },
  viewFile(e, step){
    console.log(step);
    // var url = step.description.toString().split(',')[5];
    var url = step.description.toString().split(',')[5].split(" ")[0].replace(/<[^>]*>?/gm, '');
    fetch(url, {type: 'application/pdf'})
    .then((response) => response.blob())
    .then((blob) => {
      const _url = window.URL.createObjectURL(blob);
      window.open(_url, '_blank');
    }).catch((err) => {
      console.log(err);
    });
  },
  UploadImg(e, step)
  {
    console.log(e);
    console.log(step);
    this.tempstep = step;
    var dom = document.createElement('div');
      dom.innerHTML = step.description;
      var imgtag = dom.getElementsByTagName('img');
        if(imgtag != undefined && imgtag.length > 0)
        {
          var ki;
          for(ki=0;ki<imgtag.length;ki++)
          {
            var imgtagalt = imgtag[ki].getAttribute('alt');
            var altarray = imgtagalt.split('_');
            var varName = null;
            if(altarray != undefined && altarray.length > 0)
            {
              if(altarray[0] == "mediaImage")
              {
                varName = altarray[1];
                this.ImageVarName = varName;
                this.ImageSrc = imgtagsrc;
                this.showImageBatchModal = true;
              }
            }
            var imgtagsrc = imgtag[ki].getAttribute('src');
            //console.log(imgtagalt);
          }
        }
    
  },
  handleFileUploads(){
      this.files = this.$refs.file.files[0];
      //console.log(this.files);
    },
    handleFileVideoUploads(){
      this.files = this.$refs.filevideo.files[0];
      //console.log(this.files);
    },
    addVideo(e)
    {
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append('file', this.files);
      axios
          .post("/api/uploadVideosFiles",formData,
              {
                headers: {
                        'Content-Type': 'multipart/form-data'
                }
              }
          )
          .then(function(response) {
            console.log(response);
            //app.documentTemp = response.data.item;
            app.videopath = "";
            app.videotype = "";
            var path = response.data.fileUrl;
            //console.log(response.data.fileUrl);
            //console.log(response.data.fileName);
            //console.log(response.data.uploaded);
            //var type = response.data.fileName.split(".")[1];
            //if(type == "mp4")
            //{
              app.videopath = path.toString();
              app.videotype = response.data.type.toString();
              console.log(app.videopath);
              console.log(app.videotype);
            //}
            //else
            //{
              //app.filespath = path.toString();
            //}
            
            alert("Video Uploaded Successfully");
          })
          .catch(function(error) {
            console.log(error);
            //app.documentTemp = [];
          });

    },
    addImage(e){
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append('file', this.files);
      axios
          .post("/api/uploadWidgetFiles",formData,
              {
                headers: {
                        'Content-Type': 'multipart/form-data'
                }
              }
          )
          .then(function(response) {
            console.log(response);
            //app.documentTemp = response.data.item;
            app.filespath = "";
            var path = response.data.fileUrl;
            //console.log(response.data.fileUrl);
            //console.log(response.data.fileName);
            //console.log(response.data.uploaded);

            app.filespath = path.toString();
            alert("File Uploaded Successfully");
          })
          .catch(function(error) {
            console.log(error);
            //app.documentTemp = [];
          });
    },
  formulaCalculate(e, step)
  {
    console.log(e);
    console.log(step);
    //var newstring = null;
    //console.log('old');
    //console.log(this.oldstring);
    if(this.oldstring != null && this.oldstring.length > 0)
    {
      var i;
      for(i=0;i<this.oldstring.length;i++)
      {
        step.description = step.description.toString().replace(this.oldstring[i],"");
      }
    }
    var dom = document.createElement('div');
          dom.innerHTML = step.description;
          var atag = dom.getElementsByTagName('a');
          if(atag != undefined && atag.length > 0)
          {
            var ki;
            for(ki=0;ki<atag.length;ki++)
            {
              console.log(atag[ki].innerHTML);
              var text = atag[ki].innerHTML.toString().split('=');
              if(text != undefined && text[1] != undefined)
              {
                var ResultText = "Result : " + text[1].toString();
                while(text[1].toString().search("{") > 0)
                {
                  var varname = text[1].toString().substring((text[1].toString().search("{")+1),(text[1].toString().search("}")));
                  var orgtext = text[1].toString().substring((text[1].toString().search("{")),(text[1].toString().search("}")+1));
                  //console.log(varname);
                  //console.log(orgtext);
                  var val1 = prompt("Enter Value for "+ varname, "");
                  text[1] = text[1].toString().replace(orgtext,val1);
                  ResultText = ResultText.toString().replace(orgtext,val1);
                }
                //this.$set(step, "formulaText", text[1]);
                console.log(text[1]);
                var ResultVal = eval(text[1]);
                //console.log(ResultVal);
                this.oldstring[ki] = "<br>" + ResultText + " = " + ResultVal;
                //console.log('new');
                //console.log(this.oldstring);
                step.description = step.description.toString().replace(atag[ki].innerHTML.toString(),atag[ki].innerHTML.toString() + this.oldstring[ki])
                this.formulaResultValue = ResultVal;
                //step.description = step.description + ResultText + " = " + ResultVal;
                //step.description = step.description.toString().replace(atag[ki].innerHTML.toString(),ResultVal);
              }
            }
          }
          //step.formulafound = false;

  },
  startTimer(e, step)
  {
    this.timerStart = true;
    this.spanVisible = true;
    this.timermm = 0;
    this.timerss = 0;
    this.$set(step, "timermm", this.timermm);
    this.$set(step, "timerss", this.timerss);
    this.$set(step, "timerStart", this.timerStart);
    this.$set(step, "spanVisible", this.spanVisible);
    console.log(e);
    //console.log("Start Timer");
    //console.log(step);
    this.countDownTimer(step);
  },
  stopTimer(e, step)
  {
    console.log(e);
    //console.log(step);
    this.timerStart = false;
    step.timerStart = false;
    //console.log(this.timermm + ' : ' + this.timerss);
    this.countDownTimer(step);
  },
  countDownTimer(step)
  {
    //console.log('countDownTimer');
    //console.log(step);
    //console.log(step.timerStart);
    if(step.timerStart)
    {
      setTimeout(() => {
        step.timerss += 1
        if( step.timerss == 60)
        {
           step.timerss = 0;
           step.timermm += 1;
        }
        this.countDownTimer(step);
      }, 1000)
    }

  },
  DataTrend(e, step,sec_index,step_index)
  {
    console.log(e);
    console.log(step);
    console.log(sec_index);
    console.log(step_index);
    console.log(this.documentTemp.docID);
    axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var temp = response.data.items;
          var temptable = {};
          var batchNo = '';
          this.documentdataTred = [];
          
            var i;
            for(i=0;i<temp.length;i++)
            {
              var j
              batchNo = temp[i].batchNumber;
              for(j=0;j<temp[i].sections[sec_index].steps[step_index].dataTrandings.length;j++)
              {
                temptable = {};
                temptable.batchno = batchNo;
                temptable.varname = temp[i].sections[sec_index].steps[step_index].dataTrandings[j].varname;
                temptable.varvalue = temp[i].sections[sec_index].steps[step_index].dataTrandings[j].varvalue;      
                this.$set(this.documentdataTred, this.documentdataTred.length, temptable);
              }

            }
          
          this.showDataTredBatchModal = true;
        })
        .catch(reason => {
          console.log(reason);
          this.documentdataTred = [];
        });

  },
  viewList()
  {
    axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          var temp = response.data.items;
          var temptable = {};
          this.documentviewList = [];
            for(var i=0;i<temp.length;i++){
              for(var j=0;j<temp[i].sections.length;j++){
              for(var k=0;k<temp[i].sections[j].steps.length;k++){
                temptable = {};
                if(temp[i].sections[j].steps[k].formulafound){
                var orginalDesc = temp[i].sections[j].steps[k].description;
                var formulaDesc = orginalDesc.replace(/(<([^>]+)>)/gi,"").trim();
                // tableDesc.replace(/(<([^>]+)>)/gi, "").replace(/[\n\t]+/g, "").trim().split('');
                var labelText = formulaDesc.split(' ')[1];
                var formulaResult = formulaDesc.split(' ')[12];
                var formula = temp[i].sections[j].steps[k].formulafound;
                temptable.stepno =  j+1 + "." + (k+1);
                temptable.datalabel = labelText;
                temptable.data = formulaResult;
                temptable.type = formula;
                }
                else if(temp[i].sections[j].steps[k].inputfound || temp[i].sections[j].steps[k].datatrendfound){
                  var inputDesc = temp[i].sections[j].steps[k].description;
                  var trimInputDesc = inputDesc.replace(/(<([^>]+)>)/gi,"").trim();
                  var inputLabel = trimInputDesc.split(":")[0];
                  var inputData ;
                  if(temp[i].sections[j].steps[k].inputfound){
                    inputData = trimInputDesc.split(":")[1].split("______")[0];
                  }
                  if(temp[i].sections[j].steps[k].datatrendfound){
                    inputData = trimInputDesc.split(":")[1].split(" ")[0];
                  }
                  var inputdata = temp[i].sections[j].steps[k].inputfound || temp[i].sections[j].steps[k].datatrendfound;
                  temptable.stepno =  j+1 + "." + (k+1);
                  temptable.datalabel = inputLabel;
                  temptable.data = inputData;
                  temptable.type = inputdata;
                }
                else if(temp[i].sections[j].steps[k].table){
                var tableDesc = temp[i].sections[j].steps[k].description;
                var tableLabelDesc = tableDesc.replace(/(<([^>]+)>)/gi, "").replace(/[\n\t]+/g, ",").trim();
                var labelTable = tableLabelDesc.split(',')[2] + "\n" + tableLabelDesc.split(',')[3] + "\n" + tableLabelDesc.split(',')[4] + "\n" + tableLabelDesc.split(',')[5];
                var tableResult = tableLabelDesc.split(',')[7] + "\n" + tableLabelDesc.split(',')[8] + "\n" + tableLabelDesc.split(',')[9] + "\n" + tableLabelDesc.split(',')[10];
                var table = temp[i].sections[j].steps[k].table;
                temptable.stepno =  j+1 + "." + (k+1);
                temptable.datalabel = labelTable;
                temptable.data = tableResult;
                temptable.type = table;
                }
                if (temptable && temptable.stepno) {
                  this.$set(this.documentviewList, this.documentviewList.length, temptable);
                }
                }              
              }
            }          
          this.showViewListBatchModal = true;
        })
        .catch(reason => {
          console.log(reason);
          this.documentviewList = [];
        });

  },
  showViewBatchDataButton(){
    console.log(this.documentTemp);
    for( var i = 0; i < this.documentTemp.sections.length; i++) {
      for( var j = 0; j < this.documentTemp.sections[i].steps.length; j++ ) {
          console.log(this.documentTemp.sections[i].steps[j].formulafound);
          if(this.documentTemp.sections[i].steps[j].formulafound || this.documentTemp.sections[i].steps[j].datatrendfound || this.documentTemp.sections[i].steps[j].inputfound || this.documentTemp.sections[i].steps[j].table){
          return this.documentTemp.sections[i].steps[j].formulafound || this.documentTemp.sections[i].steps[j].datatrendfound || this.documentTemp.sections[i].steps[j].inputfound || this.documentTemp.sections[i].steps[j].table;
          }
      }
    }
    return false;
  },
  skillTrend(e, step,sec_index,step_index)
  {
    console.log(e);
    console.log(step);
    console.log(sec_index);
    console.log(step_index);
    console.log(this.documentTemp.docID);
    this.graphsecIndex = sec_index;
    this.graphstepIndex = step_index;
    axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var temp = response.data.items;
          var temptable = {};
          var batchNo = '';
          this.documentSkill = [];
          
            var i;
            for(i=0;i<temp.length;i++)
            {
              var j
              batchNo = temp[i].batchNumber;
              for(j=0;j<temp[i].sections[sec_index].steps[step_index].skills.length;j++)
              {
                // step.skills[j].batchno = batchNo;
                temptable = {};
                temptable.batchno = batchNo;
                temptable.executedUsers = temp[i].sections[sec_index].steps[step_index].skills[j].executedUsers;
                temptable.verifiedUsers = temp[i].sections[sec_index].steps[step_index].skills[j].verifiedUsers;
                this.$set(this.documentSkill, this.documentSkill.length, temptable);
                }
            }
          this.showSkillTredBatchModal = true;
        })
        .catch(reason => {
          console.log(reason);
          this.documentSkill = [];
        });
  },
    setValue(e, step, secIndex, stepIndex)
    {
      this.outsideRangeTitle = secIndex + 1 + "." + (stepIndex + 1);
      this.outsideRangeSection = secIndex + 1;
      //console.log('set value');
      //console.log(step.description);
      //var DataTrandobj = [];
      var dom = document.createElement('div');
      dom.innerHTML = step.description;

      
      var atag = dom.getElementsByTagName('a');
      if(atag != undefined && atag.length > 0)
        {
          var i;
          for(i=0;i<atag.length;i++)
          {
            var ainnerHtml = atag[i].innerHTML;
            if (ainnerHtml.toString().indexOf('[DataTrend]',0) > 0)
            {
              var temparray = ainnerHtml.toString().split(':____');
              var VarName = temparray[0];
              console.log(VarName);
              var RangeName = temparray[1].substring(temparray[1].lastIndexOf('[')+1, temparray[1].length-1).split(' - ');
              var minValue = parseInt(RangeName[0]);
              var maxValue = parseInt(RangeName[1]);
              var pValText = prompt("Please Enter Value for " + VarName + ": ", "");
              if (!pValText) return;
              if(step.dataTrandings != undefined && step.dataTrandings.length > 0)
              {
                var j;
                for(j=0;j<step.dataTrandings.length;j++)
                {
                  if(step.dataTrandings[j].varname == VarName)
                  {
                    step.dataTrandings[j].varvalue = pValText;
                    step.dataTrandings[j].batchno = this.documentTemp.batchNumber;

                    //var dataTred = {};
                    //dataTred.uid = uuidv4();
                    //dataTred.varname = VarName;
                    //dataTred.varvalue = pValText;
                    //dataTred.batchno = this.documentTemp.batchNumber;
                    //dataTred.batchID = this.documentTemp.docID;
                    //dataTred.sectionid = sec_index;
                    //dataTred.stepid = step_index;
                    //this.$set(DataTrandobj, DataTrandobj, dataTred);
                  }
                }
              }
              var orgtext = ainnerHtml.toString();
              var newtext =  orgtext.replace('____',pValText);

              // if(!maxValue && !minValue){
              //   newtext =  orgtext.replace('____',pValText);
              // }
              if(pValText > maxValue || pValText < minValue){
                newtext = orgtext.replace('____','<span style="color:red;">'+pValText+'</span>').concat('<span style="color: red;"> outside acceptable range </span>');
                this.notifyOutofRange(this.documentTemp);
              }
              step.description = step.description.toString().replace(orgtext,newtext);
            }
            else if(ainnerHtml.toString().indexOf(':____',0) > 0)
            {
              temparray = ainnerHtml.toString().split(':____');
              VarName = temparray[0];
              console.log(VarName);
              pValText = prompt("Please Enter Value for " + VarName + ": ", "");
              if (!pValText) return;
              RangeName = temparray[1].substring(temparray[1].lastIndexOf('[')+1, temparray[1].length-1).split(' - ');
              minValue = parseInt(RangeName[0]);
              maxValue = parseInt(RangeName[1]);
              
              orgtext = ainnerHtml.toString();
              newtext =  orgtext.replace('____',pValText);
              console.log(orgtext);
              console.log(newtext);
              if(pValText > maxValue || pValText < minValue){
                newtext = orgtext.replace('____','<span style="color:red;">'+pValText+'</span>').concat('<span style="color: red;"> outside acceptable range </span>');
                this.notifyOutofRange(this.documentTemp);
              }
              step.description = step.description.toString().replace(orgtext,newtext);
            }
            else if(ainnerHtml.toString().indexOf(':__________',0) > 0)
            {
              temparray = ainnerHtml.toString().split(':__________');
              VarName = temparray[0];
              console.log(VarName);
              pValText = prompt("Please Enter Value for " + VarName + ": ", "");
              if (!pValText) return;
              RangeName = temparray[1].substring(temparray[1].lastIndexOf('[')+1, temparray[1].length-1).split(' - ');
              minValue = RangeName[0];
              maxValue = RangeName[1];
              orgtext = ainnerHtml.toString();
              newtext =  orgtext.replace('__________',pValText);
              console.log(orgtext);
              console.log(newtext);
              if(pValText > maxValue || pValText < minValue){
              newtext = orgtext.replace('__________','<span style="color:red;">'+pValText+'</span>').concat('<span style="color: red;"> outside acceptable range </span>');
              this.notifyOutofRange(this.documentTemp);
              }
              step.description = step.description.toString().replace(orgtext,newtext);
            }
          }
        }
    },
    onChangeExecute(e, step,sec_index,step_index) {
    console.log(e);
    axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var temp = response.data.items;
          var batchNo = '';
          var temptable = {};
          this.documentSkill = [];
            var i;
            for(i=0;i<temp.length;i++)
            {
              batchNo = temp[i].batchNumber;
              for(var j=0;j<temp[i].sections[sec_index].steps[step_index].skills.length;j++){
                  // step.skills[j].batchno = batchNo;
                  temptable = {};
                  // this.execount = this.execount + 1;
                  if (this.documentTemp.batchNumber == batchNo) {
                    var executedUser = {};
                    executedUser.executeduser = this.authCookie.userName;
                    // executedUser.executedCount = this.execount;
                    step.skills[j].executedUsers.push(executedUser);
                    step.skills[j].batchno = this.documentTemp.batchNumber;
                    temp[i].sections[sec_index].steps[step_index].skills[j].executedUsers = step.skills[j].executedUsers;
                    temp[i].sections[sec_index].steps[step_index].skills[j].verifiedUsers = step.skills[j].verifiedUsers;
                    // temp[i].sections[sec_index].steps[step_index].executedCount =  this.execount;
                  }
                  temptable.batchno = batchNo;
                  temptable.executedUsers = temp[i].sections[sec_index].steps[step_index].skills[j].executedUsers;
                  temptable.verifiedUsers = temp[i].sections[sec_index].steps[step_index].skills[j].verifiedUsers;
                  // temptable.executedCount = temp[i].sections[sec_index].steps[step_index].executedCount;
                this.$set(this.documentSkill, this.documentSkill.length, temptable);
              }
                // this.execount = this.execount + 1;
                // temp[i].sections[sec_index].steps[step_index].skills[j].executed = this.authCookie.userName;
                // temp[i].sections[sec_index].steps[step_index].executedCount ? temp[i].sections[sec_index].steps[step_index].executedCount + 1 : 1
                // temp[i].sections[sec_index].steps[step_index].executedCount =  this.execount;
                // temptable.executed = temp[i].sections[sec_index].steps[step_index].skills[j].executed;
                // temptable.verified = temp[i].sections[sec_index].steps[step_index].skills[j].verified;
                // temptable.executedCount = temp[i].sections[sec_index].steps[step_index].executedCount;
                // console.log(' executed ' + ' ' + temptable.executedCount +  ' ' + ' times ');
                // executedUser.push(temptable);
            }
        })
        .catch(reason => {
          console.log(reason);
          this.documentSkill = [];
        });
      this.$set(step, "executed", true);
      this.$set(step, "executedBy", this.authCookie.userName);
      this.$set(step, "executedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(step, "executedTime", new Date());
    },
    onChangeApprove( e, step,sec_index,step_index)
    {
      console.log(e);
      axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var temp = response.data.items;
          var batchNo = '';
          var temptable = {};
          this.documentSkill = [];
            var i;
            for(i=0;i<temp.length;i++)
            {
              var j
              batchNo = temp[i].batchNumber;
              for(j=0;j<temp[i].sections[sec_index].steps[step_index].skills.length;j++)
              {
                // step.skills[j].batchno = batchNo;
                temptable = {};
                // this.verifycount = this.verifycount + 1;
                if (this.documentTemp.batchNumber == batchNo) {
                var verifiedUser = {};
                  verifiedUser.verifieduser = this.authCookie.userName;
                  // verifiedUser.verifiedCount = this.verifycount;
                  step.skills[j].verifiedUsers.push(verifiedUser);
                  step.skills[j].batchno = this.documentTemp.batchNumber;
                  temp[i].sections[sec_index].steps[step_index].skills[j].executedUsers = step.skills[j].executedUsers;
                  temp[i].sections[sec_index].steps[step_index].skills[j].verifiedUsers = step.skills[j].verifiedUsers;
                  // temp[i].sections[sec_index].steps[step_index].verifiedCount = this.verifycount;
                }
                  temptable.batchno = batchNo;
                  temptable.executedUsers = temp[i].sections[sec_index].steps[step_index].skills[j].executedUsers;
                  temptable.verifiedUsers = temp[i].sections[sec_index].steps[step_index].skills[j].verifiedUsers;
                  // temptable.verifiedCount = temp[i].sections[sec_index].steps[step_index].verifiedCount;
              //   temptable = {};
              //   temptable.batchno = batchNo;
              //   this.verifycount = this.verifycount + 1;
              //   temp[i].sections[sec_index].steps[step_index].skills[j].executed = this.authCookie.userName;
              //   temp[i].sections[sec_index].steps[step_index].verifiedCount = this.verifycount;
              //   temp[i].sections[sec_index].steps[step_index].skills[j].verified = step.approvedBy;
              //   temptable.executed = temp[i].sections[sec_index].steps[step_index].skills[j].executed;
              //   temptable.verified = temp[i].sections[sec_index].steps[step_index].skills[j].verified;
              //   temptable.verifiedCount = temp[i].sections[sec_index].steps[step_index].verifiedCount;
              // for(var k=0; k<step.skills.length;k++){
              //   step.skills[k].batchno = this.documentTemp.batchNumber;
              //   step.skills[k].verified = step.approvedBy;
              //   step.skills[k].verifiedCount = temptable.verifiedCount;
              // }
              this.$set(this.documentSkill, this.documentSkill.length, temptable);
              }
            }
        })
        .catch(reason => {
          console.log(reason);
          this.documentSkill = [];
        });
      this.$set(step, "approved", true);
      this.$set(step, "approvedBy", this.authCookie.userName);
      this.$set(step, "approvedByOrg",this.authCookie.orgs[0].orgID);
      this.$set(step, "approvedTime", new Date());
    },
    executeStep( e, step)
    {
      var el = e.target.parentElement.parentElement.parentElement.querySelectorAll("label.form-control-label");
      var steptext;
      if(el != undefined && el[0]!= undefined && el[1] != undefined)
      {
        steptext = el[1].innerText.toString();
      }
      var tagel = e.target.parentElement.parentElement.parentElement.querySelector(".form-control-alternative");
      var u = undefined;
      if(tagel != null)
      {
        u = tagel.getElementsByTagName('u');
      }
          var i;
          if(u != undefined)
          {
            for(i=0;i<u.length;i++)
            {
              var itag = u[i].getElementsByTagName('em');
                if(itag[0]!= undefined && itag[0].innerText != undefined)
                {
                  u[i].setAttribute('id',itag[0].innerText.toString().split(',')[0]);
                  var tagid = itag[0].innerText.toString().split(',')[0];
                  var tagname = itag[0].innerText.toString().split(',')[2];
                  var type = itag[0].innerText.toString().split(',')[1];
                  this.tagjson = {};
                  this.tagjson.title = this.documentTemp.title;
                  this.tagjson.batchNumber = this.documentTemp.batchNumber;
                  this.tagjson.orgID = this.authCookie.orgs[0].orgID;
                  this.tagjson.stepNo = steptext;
                  if(type == 'equipment'){
                    this.tagjson.equID = tagid;
                  }
                  else if(type == 'reagent'){
                    this.tagjson.reagentID = tagid;
                  }
                  else if(type == 'sampleManagement'){
                    this.tagjson.sampleID = tagid;
                  }
                  else if(type == 'deviation'){
                    this.tagjson.deviationID = tagid;
                  }
                  else if(type == 'capa'){
                    this.tagjson.capaID = tagid;
                  }
                  else if(type == 'event'){
                    this.tagjson.eventID = tagid;
                  }
                  // this.tagjson.equID = tagid;
                  this.tagjson.equName = tagname;
                  this.tagjson.executedBy = this.authCookie.userName;
                  this.tagjson.executedTime = new Date();
                  this.temptag.push(this.tagjson); 
                }
            }
          }
      if(step.timer != undefined)
      {
        step.description = step.description + ' Timer(MM:SS) = ' + step.timermm + ' : ' + step.timerss ;
        this.$set(step, "timer", false);
      }
      if(step.inputfound != undefined)
      {
        this.$set(step, "inputfound", false);
      }
      if(step.mcq != undefined)
      {
        this.$set(step, "mcq", false);
      }
      if(step.qrimagefound != undefined)
      {
        this.$set(step, "qrimagefound", false);
      }
      if(step.videofound != undefined)
      {
        this.$set(step, "videofound", false);
      }
      if(step.mediaimagefound != undefined)
      {
        this.$set(step, "mediaimagefound", false);
      }
      if(step.formulafound != undefined)
      {
        this.$set(step, "formulafound", false);
      }
      if(step.grid != undefined)
      {
        this.$set(step, "grid", false);
      }
      this.$set(step, "executed", true);
      this.$set(step, "executedBy", this.authCookie.userName);
      this.$set(step, "executedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(step, "executedTime", new Date());
      console.log(step);
    },
    approveStep( e, step)
    {
      this.$set(step, "approved", true);
      this.$set(step, "approvedBy", this.authCookie.userName);
      this.$set(step, "approvedByOrg",this.authCookie.orgs[0].orgID);
      this.$set(step, "approvedTime", new Date());
    },
    notApplicableStep( e, step){
      this.$set(step, "notApplicable", true);
      this.$set(step, "notApplicableBy", this.authCookie.userName);
      this.$set(step, "notApplicableByOrg",this.authCookie.orgs[0].orgID);
      this.$set(step, "notApplicableTime", new Date());
      // if(step.notApplicable){
      //   document.getElementById('applicable').style.color='gray';
      //   for(var i=0; i<document.getElementsByClassName('ol-name').length; i++){
      //     document.getElementsByClassName('ol-name')[i].style.color='gray';
      //   }
      // }
    },
    sectionToggle(e) {
      var secBody = e.target.parentElement.querySelector(".sec-body");
      if (secBody.style.display && secBody.style.display == "none") {
        secBody.style.display = "block";
        e.target.parentElement.querySelector(".ctrl-collapse").style.display =
          "none";
        e.target.parentElement.querySelector(".ctrl-expand").style.display =
          "block";
      } else {
        secBody.style.display = "none";
        e.target.parentElement.querySelector(".ctrl-collapse").style.display =
          "block";
        e.target.parentElement.querySelector(".ctrl-expand").style.display =
          "none";
      }
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    deleteAttr(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "uiEditable")) {
        console.log("uiEditable deleted");
        delete obj["uiEditable"];
      }
      if (obj["sections"]) {
        obj["sections"].forEach(sec => {
          this.deleteAttr(sec);
        });
      }
      if (obj["steps"]) {
        obj["steps"].forEach(step => {
          this.deleteAttr(step);
        });
      }
    },
    archiveBatch() {
      let app = this;
      this.deleteAttr(this.documentTemp);
      this.documentTemp.status1 = "archived";
      axios
        .put("/api/batches", this.documentTemp)
        .then(function(response) {
          console.log(response);
          alert("Batch archived successfully!");
          window.location =
            "/documents?docStatusType="+ app.documentTemp.status1 +
            "&templateType=" +
            app.templateType;
        })
        .catch(function(error) {
          console.log(error);
          app.documentTemp = [];
        });
    },
    saveDocument() {
      this.steplock();
      let app = this;
      const textarea = document.getElementById('Textarea');
      this.deleteAttr(this.documentTemp);
      if (!this.documentTemp.batchID) {
        axios
          .post("/api/batches", app.documentTemp)
          .then(function(response) {
            console.log(response);
            app.documentTemp = response.data.item;
            if(app.textarea){
            app.textarea = textarea.value;
            console.log(app.textarea );
            }
            alert("Document Created Successfully!");
            window.location =
            "/documents?docStatusType="+ app.documentTemp.status1 +
            "&templateType=" +
            app.templateType;          
            })
          .catch(function(error) {
            console.log(error);
            app.documentTemp = [];
          });
      } 
      else {
        app.documentTemp.updatedBy =
          app.documentTemp.updatedBy || this.authCookie.userName;
        axios
          .put("/api/batches", app.documentTemp)
          .then(function(response) {
            console.log(response);
            app.documentTemp = response.data.item;
            if(app.textarea){
            app.textarea = textarea.value;
            console.log(app.textarea );
            }
            alert("Document Saved Successfully!");
            window.location =
            "/documents?docStatusType="+ app.documentTemp.status1 +
            "&templateType=" +
            app.templateType; 
          })
          .catch(function(error) {
            console.log(error);
            app.documentTemp = [];
          });
      } 
            console.log(this.temptag);
            var c
            for(c=0;c<this.temptag.length;c++)
            {
              axios
              .post("/api/addusagelog", this.temptag[c])
              .then(function(response) {
                console.log(response);
                app.usagelogtagTemp = response.data.item;
                alert("Usage Log Added Successfully!");
              })
              .catch(function(error) {
                console.log(error);
                this.usagelogtagTemp = {};
              });
            }
            this.temptag = [];
            this.tagjson = {};
    },
    notifyOutofRange(documentTempNotification){
      console.log(documentTempNotification);
      for( var i = 0; i < documentTempNotification.sections.length; i++) {
        var orginalDesc = documentTempNotification.sections[i].description;
        var strippedDesc = orginalDesc.replace(/(<([^>]+)>)/gi,"").trim();
        console.log(strippedDesc);
      for( var j = 0; j < documentTempNotification.sections[i].steps.length; j++ ) {
        var orginalStepDesc = documentTempNotification.sections[i].steps[j].description;
        var strippedStepDesc = orginalStepDesc.replace(/(<([^>]+)>)/gi,"").trim();
        console.log(strippedStepDesc);
      // if(documentTempNotification.sections[i].steps[j].dataTrandings != undefined && documentTempNotification.sections[i].steps[j].dataTrandings.length > 0){
        for( var k = 0; k < documentTempNotification.sections[i].steps[j].dataTrandings.length; k++) {
          // var rangeBatchNo = documentTempNotification.sections[i].steps[j].dataTrandings[k].batchno;
          // var rangeVarvalue = documentTempNotification.sections[i].steps[j].dataTrandings[k].varvalue;
        var notification = {
            objectId: documentTempNotification.batchID,
            type: "document",
            status: documentTempNotification.status1,
            message: " has outside acceptable range for " + documentTempNotification.title + " / " + " section " + this.outsideRangeSection + " / " + " step " + 
                              this.outsideRangeTitle + " / " + " " + documentTempNotification.batchNumber
        };
        }
      // }
      }
      }
      this.$root.$emit('listenNotification', notification);
    },
    notifyUserComment(documentTempNotification){
      console.log(documentTempNotification);
      for( var i = 0; i < documentTempNotification.sections.length; i++) {
        var orginalString = documentTempNotification.sections[i].description;
        var strippedString = orginalString.replace(/(<([^>]+)>)/gi,"").trim();
        console.log(strippedString);
      for( var j = 0; j < documentTempNotification.sections[i].steps.length; j++ ) {
        var orginalStepString = documentTempNotification.sections[i].steps[j].description;
        var strippedStepString = orginalStepString.replace(/(<([^>]+)>)/gi,"").trim();
      if(documentTempNotification.sections[i].steps[j].comments ?  documentTempNotification.sections[i].steps[j].comments.length : 0){
        for( var k = 0; k < documentTempNotification.sections[i].steps[j].comments.length; k++) {
        var notification = {
            objectId: documentTempNotification.batchID,
            type: "document",
            status: documentTempNotification.status1,
            message: " has created  comment for " + documentTempNotification.title + " " + documentTempNotification.status1  + " document under section " + this.commentSection + " " + strippedString + " step " + 
                              this.commentTitle + " " +  
                              strippedStepString + " comment description" + " " + documentTempNotification.sections[i].steps[j].comments[k].description
        };
        }
      }
      }
      }
      this.$root.$emit('listenNotification', notification);
    },
    handleOverlaysClick(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);
      }
    },
    handleOverlaysEdit(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);

        var pValTime = prompt(
          "Attach Time needed for a particular STEP",
          e.target.innerText
        );
        if (!pValTime) return;

        e.target.innerText = pValTime;
      }
    },
    getEditorInstance(el) {
      if (window.CKEDITOR.instances[el.id]) {
        return window.CKEDITOR.instances[el.id];
      }

      if (
        el["title"] &&
        el["title"].split(",").length == 2 &&
        window.CKEDITOR.instances[el["title"].split(",")[1].trim()]
      ) {
        return window.CKEDITOR.instances[el["title"].split(",")[1].trim()];
      }
      return null;
    },
    registerEditor(e) {
      this.removeSpan(e);
      if (window.CKEDITOR.instances[e.target.id]) {
        return;
      }

      if (!e.target["title"] || e.target["title"].split(",").length != 2) {
        console.dir("unregistered editor: " + e.target.id);
        window.CKEDITOR.inline(e.target.id);
        return;
      }

      var editorId = e.target["title"].split(",")[1].trim();
      if (!window.CKEDITOR.instances[editorId]) {
        console.dir("unregistered editor: " + editorId);
        window.CKEDITOR.inline(editorId);
      }

    },
    removeSpan(e)
    {
      //remove span
      var u = e.target.getElementsByTagName('u');
      var i;
          if(u != undefined && u.length>0)
          {
            for(i=0;i<u.length;i++)
            {
              if(u[i].getAttribute('id') != null)
              {
                var htmlspan1 = u[i].getElementsByTagName('span');
                if( htmlspan1.length > 0)
                {
                  htmlspan1[0].remove();
                }
              }
            } 
          }
    },
    getId() {
      return uuidv4();
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    stripHTML(strVal) {
      var div = document.createElement("div");
      div.innerHTML = strVal;
      return div.textContent || div.innerText || "";
    },
  }
};
</script>
<style>
.overlay-modal{
    position: fixed;
    z-index: 9998;
    top: 0;
    /* left: 0; */
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 17%);
    /* display: table;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;  */
}
#planet-chart{
  max-width: 500px;
  margin: 1.75rem auto;
  /* width: 500px !important;
  height: 250px !important; */
}
.comment-text {
  color: darkgray !important;
}
.comment-section {
  background-color: rgba(0, 0, 0, 0.02);
  padding-left: 10px;
}
.comment-header {
  margin-right: 15px;
  font-size: 13px;
  font-stretch: 100%;
  font-style: italic;
  font-weight: 500;
}
.comment-user {
  color: #385898;
}
.commentCountBatch
{
  position: absolute;
  top: 2px;
  left: 11px;
  color: #172b4d;
  font-size: 12px;
  /* background-color: #172b4d; */
}
.btn-comment {
  width: 10px !important;
  padding-right: 22px;
  font-size: .85rem;
}
.btn {
  border-radius: 20px;
  width: 130px;
}
.btn-step-action {
  background-color: inherit;
  /* border-color: transparent; */
  color: inherit;
  z-index: 1001;
  /* top: -45px; */
}
.step-action-btn-group {
  display: block;
}
.comment-dialog {
  max-width: 65%;
}
#editorControlListLeft
{
    height: calc(100vh - 200px);
    overflow: scroll;
    width: 65px;
}
.user-audit{
  margin-left: 5px;
  color: darkgray !important;
}
.editControlParent {
  width: 50px;
  position: fixed;
  flex-basis: auto;
}
.editRightParent {
  width: 100px;
  position: fixed;
  right: 45px;
  bottom: 50px;
  flex-basis: auto;
  margin-right: 10px;
}
.editorBatch {
  /* width: calc(100% - 320px);
  margin-left: -21px;
  flex-basis: auto; */
  margin-right: 120px;
}
.time-input::after {
  content: url(/img/pptx/time-small.png);
}

.u-photo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 45px;
}
.leftIcon {
  font-size: 10px;
  position: relative;
  top: -10px;
}
.editRightbtn {
  margin-bottom: 10px;
}
.sec-section{
  margin-top: 55px;
}
.step-section{
  /* margin-top: 55px; */
}
.comment-btn{
  margin-top: 7px;
  margin-right: 7px;
}
.step-gap-btn {
  margin-top: 7px;
  margin-right: 3px;
}
.section-toggle-comment,
.sec-toggle {
  background-color: #0001;
  color: white;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}
.section-toggle-comment.sec-toggle.accordion:after,
.sec-toggle.accordion:after {
  content: "\002B";
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.card.shadowBatch {
  border: 1px solid #dadce0 !important;
  padding: 6px;
  width: 90%;
  /* margin-top: 35px; */
}
.doc-header {
  font-weight: 700;
  /* top: -100px; */
  position: relative;
  left: 110px;
  text-align: center;
  font-size: 20px;
  /* background-color: #ffff; */
  margin-right: 95px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0.375rem;
  width: calc(100% - 270px);
}
.custom-disable {
  opacity: 0.4;
}
.custom-disable input[type=checkbox]{
  display: none;
}
input[type=checkbox]{
  display: inline;
}
.custom-disable button{
  display: none;
}
button{
  display: inline;
}
.btn-default1{
    margin-left: 5px;
    color: #fff;
    background-color: #172b4d;
    border-color: #172b4d;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.view-batch{
  margin-left: 63rem !important;
  margin-bottom: 10px;
}
.view-data{
  position: absolute;
  width: 1000px;
  top: 250px;
  left: calc(30% - 300px);
  bottom: 40px;
  z-index: 100;
}

// grid layout css

.layoutJSON {

    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}
.remove {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
}

.vue-grid-layout {
    background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}
</style>
