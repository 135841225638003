<template>
  <div>
    <div style="clear: both;">
      <document-header></document-header>
      <!-- <div class="doc_header1">Create New Document</div> -->
      <div class="container-fluid">
        <div class="row">
          <div class="doc_header1" v-if="documentTemp.title">
            {{ stripHTML(documentTemp.title) + " - " + capitalizeFirstLetter(documentTemp.status1) }}
          </div>
          <div class="doc_header1" v-else>
            {{ capitalizeFirstLetter(documentTemp.status1) }}
          </div>
        </div>
        <button class="btn-default btn-sm btn-step-action1 versionup"
        v-if="documentTemp.status1 == 'versionup'"
        @click="versionupHistory()"
        >View Version History</button>
        <div class="col editControlParent" v-if="isEditable()">
          <div id="editorControlListLeft">
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="text-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/text.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Text</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="grid-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/table.png" alt="grid" class="u-photo" />
              <span class="leftIcon">Grid</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="image-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/image.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Image</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="video-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/video.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Video</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="calendar-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/table.png" alt="calendar" class="u-photo" />
              <span class="leftIcon">Calendar</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/timer.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Timer</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="formula-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/formula-fx.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Formula</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="qrcode-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/media.jpg" alt="avatar" class="u-photo" />
              <span class="leftIcon"></span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="data-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/data_input.png" alt="Data Input" class="u-photo" />
              <span class="leftIcon">DataInput</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="var-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/variable.png" alt="Var Input" class="u-photo" />
              <span class="leftIcon">VarInput</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="steplock-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/steplock.png" alt="StepLock" class="u-photo" />
              <span class="leftIcon">StepLock</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="verifier-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/verifier.png" alt="Verifier" class="u-photo" />
              <span class="leftIcon">Verifier</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="caution-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/caution.png" alt="Caution" class="u-photo" />
              <span class="leftIcon">Caution</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="crossref-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/crossref.png" alt="crossref" class="u-photo" />
              <span class="leftIcon">CrossRef</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="iot-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/range.png" alt="range" class="u-photo" />
              <span class="leftIcon">IoT</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="mcq-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/crossref.png" alt="mcq" class="u-photo" />
              <span class="leftIcon">Mcq</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="skill-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/trending.png" alt="skill" class="u-photo" />
              <span class="leftIcon">Skill</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="location-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/location.jpg" alt="avatar" class="u-photo" />
              <span class="leftIcon">Location</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="score-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/score.png" alt="excel" class="u-photo" />
              <span class="leftIcon">Excel</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="table-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/widgets/table.png" alt="table" class="u-photo" />
              <span class="leftIcon">Table</span>
            </div>
            
          </div>
        </div>
        <div class="col editorNewDocument">
          <div class="card shadow1">
            <div class="contacts"></div>
            <div class="card-body">
            <div style="display:none">
            <form action="/api/uploadWidgetFiles" enctype="multipart/form-data" method="post">
            <input type="file" id="file" ref="file" name="file-to-upload" v-on:change="handleFileUploads()">
            <input type="submit" value="Upload">
              <base-button
                  size="sm"
                  type="default"
                  class="float-right"
                  @click="addImage($event)"
                >Add Image</base-button>
                <p v-if="filespath"><img style="width:100px;height:100px" :src="filespath" alt=""></p>
                <p v-if="videopath">
                  <video width="320" height="240" controls>
                    <source :src="videopath" :type="videotype">
                    Your browser does not support the video tag.
                  </video></p>
            </form>
            </div>
              <div
                v-if="documentTemp.title"
                v-html="documentTemp.title"
                class="form-control-alternative"
              ></div>
              <div v-if="!documentTemp.title">
                <!-- <textarea v-model.lazy="tempDocTitle" style="width: 100%"></textarea> -->
                <input type="text"
                  :id="getId()"
                  class="form-control-alternative"
                />
                <base-button style="margin-top: 10px;"
                  size="sm"
                  type="default"
                  class="float-right"
                  @click="addTitle($event)"
                >Add Title</base-button>
              </div>
              <div
                v-for="(section, sec_index) in documentTemp.sections"
                :key="section.id || section.title"
              >
                <div class="sec-toggle edit-section" @click="sectionToggle">
                  <label
                    class="form-control-label"
                    style="margin:7px"
                  >{{("Section " + (sec_index+1) + " : ")}}</label>
                  <label
                  contenteditable="true"
                    class="ckeditor-x form-control-label"
                    v-if="section.uiEditable"
                    v-html="section.description"
                  ></label>
                  <label
                    class="form-control-label"
                    v-if="section.description && !section.uiEditable"
                    v-html="section.description"
                  ></label>
                  {{ assignHTML() }}
                  <label
                    class="form-control-label ctrl-collapse"
                    style="margin-right:15px;float:right;"
                  >+</label>
                  <label
                    class="form-control-label ctrl-expand"
                    style="margin-right:15px;display:none;float:right;"
                  >-</label>
                  <div class="step-action-btn-group">
                    <button
                      class="btn float-right btn-default btn-sm btn-step-action section-edit media-edit"
                      v-if="( isEditable() == true ) && section.description && !section.uiEditable"
                      @click="editSectionDesc(section,$event)"
                    >Edit</button>
                    <button
                      class="btn float-right btn-default btn-sm btn-step-action section-edit media-edit"
                      v-if=" (isEditable()==true) && section.uiEditable"
                      @click="editSectionDescSave(section,$event)"
                    >Done</button>
                  </div>
                </div>
                <div class="sec-body" style="display:none">
                {{ assignHTML() }}
                  <!-- <div
                    v-if="section.description"
                    v-html="section.description"
                    class="form-control-alternative"
                  ></div>-->
                  <div
                    v-for="(step,step_index) in section.steps"
                    :key="step.id || step.title"
                    style="padding-left:15px ;"
                    class="step-body"
                  >
                    <label class="form-control-label">Step:</label>
                    <label class="form-control-label">{{sec_index+1 + "." + (step_index+1)}}</label>
                    <div
                      :id="step.uid"
                      contenteditable="true"
                      class="ckeditor-x form-control-alternative document-step"
                      @focus="registerEditor($event)"
                      v-if="step.uiEditable"
                      v-on:click="handleOverlaysEdit"
                      v-html="step.description"
                    ></div>
                    <!-- Section Readonly -->
                    <div
                      v-if="step.description && !step.uiEditable"
                      v-html="step.description"
                      class="form-control-alternative document-step"
                    ></div>
                    <div class="step-action-btn-group">
                      <button
                        v-if="!isApprove() && !isLibrary()"
                        class="btn float-right btn-default btn-sm btn-step-action btn-comment ni ni-chat-round media-edit"
                        @click="onClickComment(step, sec_index, step_index)"
                      ><span class="commentCount"> {{  step.comments ?  step.comments.length : "" }} </span> </button>
                      <button
                        class="btn float-right btn-default btn-sm btn-step-action media-edit"
                        v-if="( isEditable() == true ) && step.description && !step.uiEditable"
                        @click="editStepDesc(step,$event)"
                      >Edit</button>
                      <button
                        class="btn float-right btn-default btn-sm btn-step-action media-edit"
                        v-if=" (isEditable()==true) && step.uiEditable"
                        @click="editStepDescSave(step,$event)"
                      >Done</button>
                      <button class="btn float-right btn-default btn-sm btn-step-action media-edit"
                        v-if="( isEditable() == true ) && step.description && !step.uiEditable && 
                        step.description.includes('data-oembed-url') && step.description.includes('docs.google.com/spreadsheets')">
                        <a :href="extractSheetUrl(step.description)" target="_blank">View Google Sheet</a>
                      </button>
                    </div>
                    <!-- <button
                      class="btn float-right btn-default btn-sm btn-step-action"
                      @click="onClickComment(step, sec_index, step_index)"
                    >Comment</button>
                    <button
                      class="btn float-right btn-default btn-sm btn-step-action"
                      v-if="step.description && !step.uiEditable"
                      @click="editStepDesc(step,$event)"
                    >Start</button>-->
                    <!-- Section Editable -->
                    <!-- <div
                      :id="step.uid"
                      contenteditable="true"
                      class="ckeditor-x form-control-alternative"
                      @focus="registerEditor"
                      v-if="step.uiEditable"
                      v-on:click="handleOverlaysEdit"
                      v-html="step.description"
                    ></div>-->
                    <!-- <button
                      class="btn float-right btn-default btn-sm btn-step-action"
                      v-if="step.uiEditable"
                      @click="editStepDescSave(step,$event)"
                    >Done</button>-->
<!-- 
                    <div
                      v-for="(step2, step_inner_index) in step.steps"
                      :key="step2.id || step2.title"
                      style="padding-left:30px ;"
                    >
                      <label class="form-control-label">Step Inner:</label>
                      <label
                        class="form-control-label"
                      >{{sec_index+1 + "." + (step_index+1) +"." + (step_inner_index+1) }}</label>
                      <div
                        v-if="step.description"
                        v-html="step.description"
                        class="form-control-alternative"
                      ></div>
                      <label class="form-control-label">Add Step Inner:</label>
                      <div
                        :id="getId()"
                        contenteditable="true"
                        class="ckeditor-x form-control-alternative"
                        @focus="registerEditor($event)"
                        v-on:click="handleOverlaysEdit"
                      ></div>
                      <button
                        class="btn float-right btn-default btn-sm"
                        @click="addStepInner(step, $event)"
                      >Add Step Inner</button>
                    </div> -->
                  </div>
                  <div v-if="isEditable()" class="label-section">
                    <label class="form-control-label">Add Step:</label>
                    <div
                      :id="getId()"
                      contenteditable="true"
                      class="ckeditor-x form-control-alternative"
                      @focus="registerEditor($event)"
                      v-on:click="handleOverlaysEdit"
                    ></div>
                    <button
                      v-if="isEditable()"
                      class="btn float-right btn-default btn-sm"
                      @click="addStep(section, $event, sec_index,)"
                      style="margin-top: 2px;"
                    >Add Step</button>
                  </div>
                </div>
              </div>
              <div v-if="documentTemp.title" class="label-section">
                <label class="form-control-label">Add Section:</label>
                <div
                  :id="getId()"
                  contenteditable="true"
                  class="ckeditor-x form-control-alternative"
                  @focus="registerEditor($event)"
                  v-on:click="handleOverlaysEdit"
                ></div>
                <button 
                  v-if="isEditable()"
                  class="btn float-right btn-default btn-sm"
                  @click="addSection($event)"
                  style="margin-top: 3px;"
                >Add Section</button>
              </div>
            </div>
            <!-- <button class="btn float-right btn-default btn-sm" @click="saveDocument()">Save Document</button> -->
          </div>
        </div>
        <div class="col editRightParent">
          <div id="editorControlList">
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                v-if="isApprove()"
                @click="showLaunchBatchModal = true"
              >LAUNCH</button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                v-if="!isApprove() && !isLibrary()"
                @click="saveDocument()"
              >SAVE</button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
            <!--  <a href="#!" class="btn btn-info" v-if="isEditable()">REVIEW</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!-- <a href="#!" class="btn btn-info">COMMENT</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
           <!-- <a @click="showShareModal = true" class="btn btn-info">SHARE</a> -->
              <button
                id="show-share-modal"
                @click="showShareModal = true"
                class="btn btn-info"
                v-if="!isLibrary()"
              > {{ docStatusType && docStatusType.toLowerCase() == 'shared' ? "ADD COLLABRATOR" : "SHARE" }} </button> 
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                v-if="isApprove()"
                @click="showVersionupModal = true"
              >VERSION UP</button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
            <!--  <a href="#!" class="btn btn-info" v-if="isEditable()">CHECK</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-share-modal"
                @click="showApproveModal = true"
                class="btn btn-info"
                v-if="isEditable() "
              >APPROVE</button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
             <!-- <a href="#!" class="btn btn-info" v-if="isEditable()">DELETE</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Begin: Launch Batch Document -->
    <div v-if="showLaunchBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Launch Batch Document - '" + stripHTML(documentTemp.title) + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showLaunchBatchModal=false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(documentTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <span>Launch Batch Document</span>
                  <textarea
                    v-model="batchDocNumber"
                    placeholder="Enter batch #"
                    style="width:100%"
                  ></textarea>
                  <button
                    v-if="finalarray.length == 0"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="launchBatch()"
                  >Launch Batch</button>
                  <button
                    v-if="finalarray.length == 0"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="setToDefault()"
                  >Cancel</button>

                  <div v-if="finalarray.length > 0" style="max-height: calc(100vh - 210px); overflow-y: auto;">
                    <span>Set Variable values:</span>
                    <div v-for="section in finalarray" v-bind:key="section.uid">
                      <table><tr>
                        <td style="width:20%">Step: <label class="form-control-label" v-if="section.sectionnum" v-html="section.sectionnum"></label></td>
                        <td style="width:35%">Variable Name: <label class="form-control-label" v-if="section.variablename" v-html="section.variablename"></label></td>
                        <td style="width:50%">Value: <input type="text" class="form-control-alternative" v-bind:value="section.defaultvalue" v-on:input="onValueChange(section,$event)" /></td>
                      </tr></table>
                    </div>
                      <button  
                      class="btn float-right btn-default btn-sm m-2"
                      @click="launchBatch()"
                    >Launch Batch</button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="setToDefault()"
                    >Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Launch Batch Document -->

    <!-- Begin: Share Document -->
    <div v-if="showShareModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Share Document - '" + stripHTML(documentTemp.title) + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showShareModal=false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(documentTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <!-- <span>Share Document to :</span> -->
                  <div class="tab">
                    <button class="tablinks active" @click="openShare( 'shareContent',$event)">Share</button>
                    <button class="tablinks" @click="openShare( 'libraryContent',$event)">Library</button>
                    <button v-if="isApprove()" class="tablinks" @click="openShare( 'publicLink',$event)">Public Sharing</button>
                  </div>

                  <div id="shareContent" class="tabcontent" style="display:block">
                    <h3>Send to Emails</h3>
                    <textarea
                      v-model="emailAddress"
                      placeholder="Email address (separated by ; )"
                      style="width:100%"
                    ></textarea>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="shareDocument()"
                    >Share</button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="showShareModal = false"
                    >Cancel</button>
                  </div>

                  <div id="libraryContent" class="tabcontent">
                    <h3>Send to DigitalGxP Public Library</h3>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="shareLibraryDocument()"
                    >Share</button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="showShareModal = false"
                    >Cancel</button>
                  </div>
                  <div id="publicLink" class="tabcontent">
                    <div class="copy-url">
                      <textarea id="linkurl" style="width: 100%;" v-model="this.documentTemp.publicUrl" ref="message"></textarea>
                      <!-- <input id="linkurl" v-model="this.documentTemp.publicUrl" ref="message" /> -->
                    </div>
                    <button style="margin-top: 4px;" @click="copyUrl()">Click to Copy</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Share Document -->

    <!-- Begin: Comment-->
    <div v-if="showCommentModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog comment-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Add Comment for Step : '" + commentTitle + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCommentModal=false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Comments display-->
                <div>
                  <div class="sec-toggle comment-section" @click="sectionToggleComment">
                    <label
                      class="form-control-label comment-header ctrl-collapse"
                      style="display:none;width: fit-content;"
                      @click="sectionToggleComment"
                    >+ Comments</label>
                    <label
                      class="form-control-label comment-header ctrl-expand"
                      style="width: fit-content;"
                      @click="sectionToggleComment"
                    >- Comments</label>
                  </div>
                  <div class="sec-body">
                    <div
                      v-for="(comment, comment_index) in this.currentStep.comments"
                      :key="comment.id || comment_index"
                      style="padding-left:15px ;"
                      class="comment-text"
                    >
                      <span class="comment-user">{{comment.createdBy}}</span>
                      {{ comment.description + " - on " + formatDate(comment.createdTime)}}
                    </div>
                  </div>
                </div>

                <div class="modal-body">
                  <span>Comments</span>
                  <textarea
                    v-model="commentDescription"
                    placeholder="Enter your comment"
                    style="width:100%"
                  ></textarea>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="addComment()"
                  >Add Comment</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showCommentModal = false"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Comment -->

    <!-- Begin: Approve Document -->
    <div v-if="showApproveModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Approve Document - '" + stripHTML(documentTemp.title) + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showApproveModal=false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(documentTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <span>Please confirm!</span>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="approveDocument()"
                  >Approve</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showApproveModal = false"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Approve Document -->

    <!-- Begin: versionup Document -->
    <div v-if="showVersionupModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Version-Up Document - '" + stripHTML(documentTemp.title) + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showVersionupModal=false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Please confirm!</span>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="versionupDocument()"
                  >Confirm</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showVersionupModal = false"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: versionup Document -->


    <!-- {{this.documentTemp}} -->
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" @click="showModal=false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 class="modal-title">Modal title</h4>
                </div>
                <div class="modal-body">modal body</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- <button id="show-modal" @click="showModal = true">Show Modal 123</button> -->

    <div v-if="showVersionupHistoryModal">
        <transition name="modal">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog-wider modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    {{ "Version up editable data" }}
                    <button
                      type="button"
                      class="close"
                      @click="showVersionupHistoryModal=false">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="table-responsive">
                        <base-table
                          class="table align-items-center table-flush formula-datainput-table-list"
                          id="batch-table"
                          tbody-classes="list"
                          :data="documentviewHistory"
                        >
                          <template slot="columns">
                            <th>User Name</th>
                            <th>Section No</th>
                            <th>Section Description</th>
                            <th>Step No</th>
                            <th>Step Description</th>
                            <!-- <th>Change Description</th> -->
                          </template>
                          <template slot-scope="{row}">
                            <td> {{row.userName}} </td>
                            <td style="white-space:pre-line;"> {{row.sectionno}} </td>
                            <td style="white-space:pre-line;"> <del>{{row.olddesc}}</del>  {{row.newdesc}} </td>
                            <td style="white-space:pre-line;"> {{row.stepno}} </td>
                            <td style="white-space:pre-line;"> <del>{{row.stepolddesc}}</del>  {{row.stepnewdesc}} </td>
                          </template>
                        </base-table>
                    </div>
                    <button @click="showVersionupHistoryModal=false"
                    class="btn float-right btn-default btn-sm m-2">
                    Ok</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showVersionupHistoryModal=false"
                  >Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
    </div>

  </div>
</template>
<script>
/* eslint-disable no-console */

import axios from "../../../utils/axiosinstance";
// const { GoogleSpreadsheet } = require('google-spreadsheet');
// import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import documentHeader from "@/views/process/documentsv1/documentHeader";
// import draggable from 'vuedraggable';
export default {
  components: { documentHeader },
  // props: ["docid", "docStatusType", "shareDocId"],
  data() {
    return {
      showVersionupHistoryModal: false,
      documentviewHistory: [],
      shareDocId: "",
      docStatusType: "",
      multipleChoice: '',
      message: '',
      showModal: false,
      showShareModal: false,
      showCommentModal: false,
      showApproveModal: false,
      showVersionupModal: false,
      showLaunchBatchModal: false,
      documentTemp: {},
      finaljson: {},
      finalarray:[],
      authCookie: null,
      editIds: [],
      batchDocNumber: "",
      emailAddress: "",
      currentStep: "",
      commentTitle: "",
      commentSection: "",
      commentDescription: "",
      files: '', 
      filespath: '',
      videopath:'',
      videotype:'',
      newComments : [],
    }
  },
  beforeMount() {
    this.shareDocId = this.$route.query.shareDocId;
    this.documentTemp.docID = this.$route.query.docid;
    this.documentTemp.status1 = this.$route.query.docStatusType;
    this.documentTemp.templateType = this.$route.query.templateType;

    if (!this.$route.query.docid) {
      this.documentTemp.industryItemType = this.$route.query.industryItemType;
      this.documentTemp.industryItemID = this.$route.query.industryItemID;
    }
  },
  mounted() {
    console.log(
      " docid: " +
        this.documentTemp.docID +
        " docStatusType: " +
        this.documentTemp.status1 +
        " templateType: " +
        this.documentTemp.templateType);
    if (!this.documentTemp.docID && !this.shareDocId) {
      // let urlParams = new URLSearchParams(window.location.search);

      // let sharedKey = urlParams.get('key');
      let sharedKey = this.$route.params.key;
      if (sharedKey){
        this.shareDocId = sharedKey;
      }
    }
    if (this.documentTemp.docID) {
      axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/documents/" +
            this.documentTemp.docID
        )
        .then(response => {
          console.log(response);
          this.documentTemp = response.data.item;

          //console.log('Tru');
          var i = 0;
          var totalsec = this.documentTemp.sections;
          //var latestarray = [];
          for(i=0;i<totalsec.length;i++)
          {
            var j = 0;
            var eachstep = totalsec[i].steps
            for(j=0;j<eachstep.length;j++)
            {
              //var v1 = eachstep[j].description.toString();
              var dom = document.createElement('div');
              dom.innerHTML = eachstep[j].description;
              // var ptag = dom.getElementsByTagName('u');
              // for(var l=0; l<ptag.length; l++){
              //   if(ptag[l].innerText.toString().split(',')[1] == "pdf"){
              //     console.log("event on button");
              //     ptag[l].onclick = function(){
              //           console.log("event on button");
              //           var url = ptag[l].innerText.toString().split(',')[5];
              //           fetch(url, {type: 'application/pdf'})
              //           .then((response) => response.blob())
              //           .then((blob) => {
              //             const _url = window.URL.createObjectURL(blob);
              //             window.open(_url, '_blank');
              //           }).catch((err) => {
              //             console.log(err);
              //           })
              //           }
              //   }
              //   }
              var atag = dom.getElementsByTagName('a');
              if(atag != undefined && atag.length > 0)
              {
                var k;
                for(k=0;k<atag.length;k++)
                {
                  if (eachstep[j].mcq) {
                    continue;
                  }
                  if(!(atag[k].innerHTML.toString().indexOf('Formula = ',0) > 0) && atag[k].innerHTML.toString().split(',')[1] != undefined)
                  {
                    this.finaljson = {};
                    //console.log(atag[k].innerHTML);
                    this.finaljson.uid = uuidv4();
                    this.finaljson.sectionnum = ((i+1) +'.'+ (j+1));
                    this.finaljson.variablename = atag[k].innerHTML.toString().split(',')[0].substring(1,atag[k].innerHTML.toString().split(',')[0].length);
                    this.finaljson.defaultvalue = atag[k].innerHTML.toString().split(',')[1].substring(0,(atag[k].innerHTML.toString().split(',')[1].length)-1);
                    this.finaljson.oldvalue = atag[k].innerHTML.toString().split(',')[1].substring(0,(atag[k].innerHTML.toString().split(',')[1].length)-1);
                    this.finalarray.push(this.finaljson);
                    //this.finalarray = this.finalarray.concat(this.finalarray);
                    //console.log('i ' + i + ' j ' + j + ' k ' + k);
                    //console.log(this.finaljson);
                    //console.log('final array');
                    //console.log(this.finalarray);
                  }
                }
              }
              
              //this.finalarray.push(this.finaljson);
            }
            //this.finalarray.push(this.finaljson);
          }
          //this.finalarray.push(this.finaljson);

          //var v1 = this.documentTemp.sections[0].steps[2].description.toString();
          //console.log('final array');
          //console.log(this.finalarray);
         
          //v2.innerHTML.toString());

          this.documentTemp.title = this.documentTemp.title.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]/g, '');

          //load OGP.ME
          const titleEl = document.querySelector('head title');
          titleEl.textContent = "Digital GxP - " + this.documentTemp.title;
          
          //u[i].setAttribute('id',u[i].getElementsByTagName('em')[0].innerText.toString().split(',')[0]);
          //alert(u[i].getElementsByTagName('em')[0]);

          // const descEl = document.querySelector('head meta[name="description"]');
          // descEl.setAttribute('content', this.documentTemp.title);
          
          // const ogTitleEl = document.querySelector('head meta[property="og:title"]');
          // ogTitleEl.setAttribute('content',  "Digital GxP - " +this.documentTemp.title);
          
          // const ogUrlEl = document.querySelector('head meta[property="og:url"]');
          // ogUrlEl.setAttribute('content', window.location);
          
          // const ogDescEl = document.querySelector('head meta[property="og:description"]');
          // ogDescEl.setAttribute('content', this.documentTemp.title);
          
          // const descEl = document.querySelector('head meta[property="og:image"]');
          // descEl.setAttribute('content', this.documentTemp.title);
        })
        .catch(reason => {
          console.log(reason);
          this.documentTemp = [];
        });
    } else if (this.shareDocId) {
      console.log(" Opening from Share id :" + this.shareDocId);
      var that = this;
      axios
        .get("/api/sharedDocuments?key=" + this.shareDocId)
        .then(response => {
          that.documentTemp = response.data.item;
          that.documentTemp.docID = response.data.item.docID;
          that.documentTemp.status1 = response.data.item.status1;

          this.documentTemp.title = this.documentTemp.title.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]/g, '');

          
          //load OGP.ME
          const titleEl = document.querySelector('head title');
          titleEl.textContent = "Digital GxP - " + that.documentTemp.title;


          // const descEl = document.querySelector('head meta[name="description"]');
          // descEl.setAttribute('content', that.documentTemp.title);
          
          // const ogTitleEl = document.querySelector('head meta[property="og:title"]');
          // ogTitleEl.setAttribute('content',  "Digital GxP - " +that.documentTemp.title);
          
          // const ogUrlEl = document.querySelector('head meta[property="og:url"]');
          // ogUrlEl.setAttribute('content', window.location);
          
          // const ogDescEl = document.querySelector('head meta[property="og:description"]');
          // ogDescEl.setAttribute('content', that.documentTemp.title);
          
          // const descEl = document.querySelector('head meta[property="og:image"]');
          // descEl.setAttribute('content', that.documentTemp.title);
        })
        .catch(reason => {
          console.log(reason);
          that.documentTemp = [];
        });
    }
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    if (authCookieObj){
      // this.authCookie = JSON.parse(authCookieObj.substring(2));
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token")
              .substring(2)
              .split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      this.authCookie = authObj;
    }
    // this.accessSpreadSheet();
  },
  beforeCreate() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "/ckeditor.js");
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    versionupHistory(){
      axios
        .get(
          "/api/documents/organizations/" +
            this.authCookie.orgs[0].orgID + "/" +
            this.documentTemp.docID
        )
        .then(response => {
          var temp = response.data.items;
          var temptable = {};
          this.documentviewHistory = [];
            for(var i=0;i<temp.length;i++){
              for(var j=0;j<temp[i].changes.length;j++){
                temptable = {};
                temptable.userName = temp[i].userName;
                temptable.olddesc = temp[i].changes[j].oldValue;
                temptable.newdesc = temp[i].changes[j].newValue;
                temptable.sectionno =  temp[i].changes[j].sectionNo;
                if(temp[i].changes[j].steps.length == 0){
                this.$set(this.documentviewHistory, this.documentviewHistory.length, temptable);
                }
                for(var k=0;k<temp[i].changes[j].steps.length;k++){
                    temptable = {};
                    temptable.userName = temp[i].userName;
                    temptable.olddesc = temp[i].changes[j].oldValue;
                    temptable.newdesc = temp[i].changes[j].newValue;
                    temptable.sectionno =  temp[i].changes[j].sectionNo;
                    temptable.stepolddesc = temp[i].changes[j].steps[k].oldValue;
                    temptable.stepnewdesc = temp[i].changes[j].steps[k].newValue;
                    temptable.stepno =  temp[i].changes[j].steps[k].stepNo;
                    this.$set(this.documentviewHistory, this.documentviewHistory.length, temptable);
                }
              }
            }          
        })
        .catch(reason => {
          console.log(reason);
          this.documentviewHistory = [];
        });
      this.showVersionupHistoryModal = true;
    },
    copyUrl() {
      const element = this.$refs.message;
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand('copy');
      // var copyText = document.getElementById("myInput").innerHTML;
      // copyText.select();
      // copyText.setSelectionRange(0, 99999);
      // navigator.clipboard.writeText(copyText.value);
      
      // var tooltip = document.getElementById("myTooltip");
      // tooltip.innerHTML = "Copied: " + copyText.value;
    },
    extractSheetUrl(HTMLString) {
      var doc = new DOMParser().parseFromString(HTMLString, "text/html")
      const element = doc.getElementsByTagName("div")[0]; 
      console.log(element);
      let url = element.getAttribute("data-oembed-url"); 
      console.log(url);
      return url;
    },
    // async accessSpreadSheet() {
		// 		const doc = new GoogleSpreadsheet('step.description');
		// 		// await doc.useServiceAccountAuth(creds);
		// 		await doc.loadInfo(); 
    //     console.log(doc);
		// 	},
    isEditable() {
      // console.log(this.documentTemp.status1);
      return (
        !this.documentTemp.status1 ||
        ["approved", "library", "shared", "archived", "template"].indexOf(
          this.documentTemp.status1.toLowerCase()
        ) < 0
      );
    },
    isApprove() {
      return (
        this.documentTemp.status1 &&
        ["approved"].indexOf(this.documentTemp.status1.toLowerCase()) >= 0
      );
    },
    isLibrary() {
      return (
        this.documentTemp.status1 &&
        ["template"].indexOf(this.documentTemp.status1.toLowerCase()) >= 0
      );
    },
    isShare() {
      return (
        !(this.authCookie && this.authCookie.userName) &&
        this.documentTemp.status1 &&
        ["shared"].indexOf(this.documentTemp.status1.toLowerCase()) >= 0
      );
    },
    capitalizeFirstLetter(strVal) {
      if (strVal) return strVal.charAt(0).toUpperCase() + strVal.slice(1);
      return "";
    },
    assignHTML(){
      setTimeout(function() {
        var u = document.getElementsByTagName('u');
          var i;
          if(u != undefined)
          {
            for(i=0;i<u.length;i++)
            {
              if(u[i].getAttribute('id') == null)
              {
                var itag = u[i].getElementsByTagName('em');
                if(itag[0]!= undefined && itag[0].innerText != undefined)
                {
                  u[i].setAttribute('id',itag[0].innerText.toString().split(',')[0]);
                  u[i].setAttribute('class','tooltip1');
                  //console.log('try');
                  //console.log(itag[0].innerText.toString().split(',')[4]);
                  if(itag[0].innerText.toString().split(',')[4] != '')
                  {
                    u[i].insertAdjacentHTML('beforeend', '<span class="tooltiptextimg">Type: '+ itag[0].innerText.toString().split(',')[1] +'<br> Description: '+ itag[0].innerText.toString().split(',')[3] +'<br> Image: <img style="width:30px; height:30px; margin-bottom: 10px;" src=' + itag[0].innerText.toString().split(',')[4] + '> </span>');
                  }
                  if(itag[0].innerText.toString().split(',')[1] == "pdf")
                  {
                    u[i].insertAdjacentHTML('beforeend', '<span class="tooltiptextimg">Type: '+ itag[0].innerText.toString().split(',')[1] +'<br> Description: '+ itag[0].innerText.toString().split(',')[3] +'<br> file: '+ itag[0].innerText.toString().split(',')[5] +'</span>');
                    u[i].onclick = function(e){
                    if(e.target.textContent.split(",")[1] == "pdf"){
                      var url = event.target.textContent.split(",")[5].split(" ")[0];
                      fetch(url, {type: 'application/pdf'})
                      .then((response) => response.blob())
                      .then((blob) => {
                        const _url = window.URL.createObjectURL(blob);
                        window.open(_url, '_blank');
                      }).catch((err) => {
                        console.log(err);
                      });
                    }
                    };
                  }
                  if(itag[0].innerText.toString().split(',')[1] == "images")
                  {
                    u[i].insertAdjacentHTML('beforeend', '<span class="tooltiptextimg">Type: '+ itag[0].innerText.toString().split(',')[1] +'<br> Description: '+ itag[0].innerText.toString().split(',')[3] +'<br> imagefile: <img style="width:30px; height:30px; margin-bottom: 10px;" src=' + itag[0].innerText.toString().split(',')[5] + '></span>');
                    u[i].onclick = function(e){
                      if(e.target.textContent.split(",")[1] == "images"){
                      var url = event.target.textContent.split(",")[5].split(" ")[0];
                    // var url = itag[0].innerText.toString().split(',')[5];
                    fetch(url, {type: 'image/jpg'})
                    .then((response) => response.blob())
                    .then((blob) => {
                      const _imageurl = window.URL.createObjectURL(blob);
                      window.open(_imageurl, '_blank');
                    }).catch((err) => {
                      console.log(err);
                    });
                      }
                    };
                  }
                  if(itag[0].innerText.toString().split(',')[1] == "videos")
                  {
                    u[i].insertAdjacentHTML('beforeend', '<span class="tooltiptextimg">Type: '+ itag[0].innerText.toString().split(',')[1] +'<br> Description: '+ itag[0].innerText.toString().split(',')[3] +'<br> videofile: <video width="100" height="50" controls><source src=' + itag[0].innerText.toString().split(',')[5] + ' type="video/mp4" /></video></span>');
                    u[i].onclick = function(e){
                      if(e.target.textContent.split(",")[1] == "videos"){
                      var url = event.target.textContent.split(",")[5].split(" ")[0];
                    fetch(url, {type: 'video/mp4'})
                    .then((response) => response.blob())
                    .then((blob) => {
                      const _videourl = window.URL.createObjectURL(blob);
                      window.open(_videourl, '_blank');
                    }).catch((err) => {
                      console.log(err);
                    });
                      }
                    };
                  }
                  if(itag[0].innerText.toString().split(',')[1] == "template" || itag[0].innerText.toString().split(',')[1] == "library")
                  {
                    u[i].insertAdjacentHTML('beforeend', '<span class="tooltiptextimg">Type: '+ itag[0].innerText.toString().split(',')[1] +'<br> title: '+ itag[0].innerText.toString().split(',')[2]+'</span>');
                  }
                  if(itag[0].innerText.toString().split(',')[1] == "dataCenter")
                  {
                    u[i].insertAdjacentHTML('beforeend', '<span class="tooltiptextimg">Type: '+ itag[0].innerText.toString().split(',')[1] +'<br> title: '+ itag[0].innerText.toString().split(',')[2] +'<br> imagefile: <img style="width:30px; height:30px; margin-bottom: 10px;" src=' + itag[0].innerText.toString().split(',')[5] + ' ></span>');
                  }
                  // else
                  // {
                  //   u[i].insertAdjacentHTML('beforeend', '<span class="tooltiptext">Type: '+ itag[0].innerText.toString().split(',')[1] +'<br> Description: '+ itag[0].innerText.toString().split(',')[3] +'</span>');
                  // }
                  // if(itag[0].innerText.toString().split(',')[1] == "pdf"){
                  //   u[i].onclick = function(){
                  //   console.log("event on button");
                  //   var url = itag[0].innerText.toString().split(',')[5];
                  //   fetch(url, {type: 'application/pdf'})
                  //   .then((response) => response.blob())
                  //   .then((blob) => {
                  //     const _url = window.URL.createObjectURL(blob);
                  //     window.open(_url, '_blank');
                  //   }).catch((err) => {
                  //     console.log(err);
                  //   });
                  //   };
                  // }
                }
                
              }
            } 
          }
				}, 100);
    },
    stripHTML(strVal) {
      var div = document.createElement("div");
      div.innerHTML = strVal;
      return div.textContent || div.innerText || "";
    },
    deleteAttr(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "uiEditable")) {
        console.log("uiEditable deleted");
        delete obj["uiEditable"];
      }
      if (obj["sections"]) {
        obj["sections"].forEach(sec => {
          this.deleteAttr(sec);
        });
      }
      if (obj["steps"]) {
        obj["steps"].forEach(step => {
          this.deleteAttr(step);
        });
      }
    },
    addCommentStepDesc(step, e) {
      var el = e.target.parentElement.parentElement.querySelector(".ckeditor-x");
      // console.dir(el);
      var editor = this.getEditorInstance(el);
      if (editor){
        // console.dir(editor);this.currentStep.comment
        this.$set(step, "description", editor.getData());
        console.dir(editor.getData());

        editor.setData("");
      } else {
        this.$set(step, "description", el.innerHTML);
      }
      this.$set(step, "uiEditable", false);
    },
    editStepDesc(step) {
      this.$set(step, "uiEditable", true);
    },
    editSectionDesc(section) {
      this.$set(section, "uiEditable", true);
    },
    editStepDescSave(step, e) {
      var el = e.target.parentElement.parentElement.querySelector(".ckeditor-x");
      // console.dir(el);
      var editor = this.getEditorInstance(el);
      if (editor){
        // console.dir(editor);
        this.$set(step, "description", editor.getData());
        // this.$set(step, "editable", true);
        console.dir(editor.getData());

        //editor.setData("");
      } else {
        this.$set(step, "description", el.innerHTML);
      }
      this.$set(step, "uiEditable", false);
    },
    editSectionDescSave(section, e) {
      var el = e.target.parentElement.parentElement.querySelector(".ckeditor-x");
      // console.dir(el);
      var editor = this.getEditorInstance(el);

      if (editor){
        // console.dir(editor);
        this.$set(section, "description", editor.getData());
        console.dir(editor.getData());

        //editor.setData("");
      } else {
        this.$set(section, "description", el.innerHTML);
      }
      this.$set(section, "uiEditable", false);
    },
    sectionToggleComment(e) {
      let parentElement = e.target.parentElement;
      if (e.classList && e.classList.contains("sec-toggle")) {
        parentElement = e.parentElement;
      }
      var secBody = parentElement.querySelector(".sec-body");
      if (secBody.style.display && secBody.style.display == "none") {
        secBody.style.display = "block";
        parentElement.querySelector(".ctrl-collapse").style.display = "none";
        parentElement.querySelector(".ctrl-expand").style.display = "block";
      } else {
        secBody.style.display = "none";
        parentElement.querySelector(".ctrl-collapse").style.display = "block";
        parentElement.querySelector(".ctrl-expand").style.display = "none";
      }
    },
    sectionToggle(e) {
      let parentElement = e.target.parentElement;
      var secBody = parentElement.querySelector(".sec-body");
      if (secBody.style.display && secBody.style.display == "none") {
        secBody.style.display = "block";
        parentElement.querySelector(".ctrl-collapse").style.display = "none";
        parentElement.querySelector(".ctrl-expand").style.display = "block";
      } else {
        secBody.style.display = "none";
        parentElement.querySelector(".ctrl-collapse").style.display = "block";
        parentElement.querySelector(".ctrl-expand").style.display = "none";
      }
    },
    async saveDocument() {
      let app = this;
      this.deleteAttr(this.documentTemp);
      if (!this.documentTemp.docID) {
        try {
          const { data } = await axios.post("/api/documents", app.documentTemp);
          console.log(data);
        // axios
        //   .post("/api/documents", app.documentTemp)
        //   .then(function(response) {
            // console.log(response);
            app.documentTemp = data.item;
            alert("Document Created Successfully!");
            // window.location = "/documents/draft";
            window.location =
            "/new?docid=" +
            app.documentTemp.docID +
            "&templateType=" +
            app.documentTemp.templateType +
            '&docStatusType='+ app.documentTemp.status1;
            // if(callBackFn) {
            //     callBackFn();
            // }
          }
          catch (error) {
            console.log(error);
            // app.documentTemp = [];
          }
      } else {
        app.documentTemp.updatedBy =
          app.documentTemp.updatedBy || this.authCookie.userName;
          try {
          const { data } = await axios.put("/api/documents", {
            document: app.documentTemp,
            changes: { comment: this.newComments },
          });
        // axios
        //   .put("/api/documents", { document: app.documentTemp, changes : {comment : this.newComments}} )
        //   .then(function(response) {
            console.log(data);
            app.documentTemp = data.item;
            alert("Document Saved Successfully!");
            // if(app.documentTemp.status1 == 'draft') {
            //   window.location = "/documents/draft";
            // }
            // if(app.documentTemp.status1 == 'versionup') {
            //   window.location = "/documents/versionup";
            // }
            // window.location = "/documents/draft";
            if(app.documentTemp.status1 == 'approved') {
              app.notifyUser(app.documentTemp);
            }
            // if(app.documentTemp.status1 == 'versionup'){
            //     try {
            //   const { data } = await axios.post("/api/auditTrail", app.documentTemp);
            //     app.documentTemp = data.item;
            //     alert("versionup updated Successfully!");
            //   }
            //   catch (error) {
            //     console.log(error);
            //   }
            // }
            window.location =
            "/new?docid=" +
            app.documentTemp.docID +
            "&templateType=" +
            app.documentTemp.templateType + 
            '&docStatusType='+ app.documentTemp.status1;
          }
          catch (error) {
            console.log(error);
            app.documentTemp = [];
          }
      }
    },
    handleOverlaysClick(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);
      }
    },
    handleOverlaysEdit(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);

        var pValTime = prompt(
          "Attach Time needed for a particular STEP",
          e.target.innerText
        );
        if (!pValTime) return;

        e.target.innerText = pValTime;
      }
    },
    getEditorInstance(el) {
      if (window.CKEDITOR.instances[el.id]) {
        return window.CKEDITOR.instances[el.id];
      }

      if (
        el["title"] &&
        el["title"].split(",").length == 2 &&
        window.CKEDITOR.instances[el["title"].split(",")[1].trim()]
      ) {
        return window.CKEDITOR.instances[el["title"].split(",")[1].trim()];
      }
      return null;
    },
    registerEditor(e) {
      this.removeSpan(e);
      if (window.CKEDITOR.instances[e.target.id]) {
        return;
      }

      if (!e.target["title"] || e.target["title"].split(",").length != 2) {
        console.dir("unregistered editor: " + e.target.id);
        window.CKEDITOR.inline(e.target.id);
        return;
      }

      var editorId = e.target["title"].split(",")[1].trim();
      if (!window.CKEDITOR.instances[editorId]) {
        console.dir("unregistered editor: " + editorId);
        window.CKEDITOR.inline(editorId);
      }

    },
    removeSpan(e)
    {
      //remove span
      var u = e.target.getElementsByTagName('u');
      var i;
          if(u != undefined && u.length>0)
          {
            for(i=0;i<u.length;i++)
            {
              if(u[i].getAttribute('id') != null)
              {
                var htmlspan1 = u[i].getElementsByTagName('span');
                if( htmlspan1.length > 0)
                {
                  htmlspan1[0].remove();
                }
              }
            } 
          }
    },
    getId() {
      return uuidv4();
    },
    addStepInner(stepArr, e) {
      this.addStep(stepArr, e);
    },
    addStep(sectionParent, e) {
      var el = e.target.parentElement.querySelector(".ckeditor-x");
      // var contact = e.target.parentElement.getAttribute("data-class-name");
      // e.dataTransfer.setData("data-class-name", contact);
      // var dragControl = e.dataTransfer.getData("data-class-name");
      var editor = this.getEditorInstance(el);

      if (!sectionParent.steps) {
        this.$set(sectionParent, "steps", []);
      }
      
      var step = {};
      step.uid = uuidv4();
      step.description = editor.getData();
      // step.type = editor.getData();
      step.dataTrandings = [];
      step.skills = [];
      if(editor.getData().toString().indexOf('Upload Video: ',0) > 0)
      {
        step.videofound = true;
      }
      if(editor.getData().toString().split(",")[1] == "pdf" || editor.getData().toString().split(",")[1] == "videos" || editor.getData().toString().split(",")[1] == "images")
      {
        step.viewFile = true;
      }
      if(editor.getData().toString().indexOf('formula ',0) > 0)
      {
        // editor.getData().toString().indexOf('formula =',0) > 0)
        step.formulafound = true;
      }
      if(editor.getData().toString().indexOf('Options = ',0) > 0)
      {
        step.mcq = true;
        // this.multipleChoice = step.mcq;
      }
      if(editor.getData().toString().indexOf('Table:',0) > 0)
      {
        step.table = true;
        // this.multipleChoice = step.mcq;
      }
      if (editor.getData().toString().indexOf(':__________',0) > 0)
      {
        step.inputfound = true;
      }
      else if (editor.getData().toString().indexOf(':____',0) > 0)
      {
        step.inputfound = true;
      }
      var dom = document.createElement('div');
      dom.innerHTML = editor.getData();
      var imgtag = dom.getElementsByTagName('img');
        if(imgtag != undefined && imgtag.length > 0)
        {
          var ki;
          for(ki=0;ki<imgtag.length;ki++)
          {
            var imgtagalt = imgtag[ki].getAttribute('alt');
            var imgtagsrc = imgtag[ki].getAttribute('src');
            //console.log(imgtagalt);
            if(imgtagsrc.toString() == "/img/pptx/qr-code.png")
            {
              step.qrimagefound = true;
            }
            if(imgtagsrc.toString() == "/img/pptx/image-old.png")
            {
              step.mediaimagefound = true;
            }
            if(imgtagalt.toString() == "Timer")
              {
                step.timer = true;
              }
            if(imgtagalt.toString() == "Location")
              {
                step.location = true;
              }
              if(imgtagalt.toString() == "Grid")
              {
                step.grid = true;
              }
            if(imgtagalt.toString() == "Skill")
              {
                var skill = {};
                skill.uid = uuidv4();
                skill.batchno = '';
                skill.executedUsers = [];
                skill.verifiedUsers = [];
                // skill.executedCount = null;
                console.log(skill);
                this.$set(step.skills, step.skills.length, skill);
                step.skillfound = true;
              }
          }
        }
      var atag = dom.getElementsByTagName('a');
      if(atag != undefined && atag.length > 0)
        {
          var i;
          for(i=0;i<atag.length;i++)
          {
            var ainnerHtml = atag[i].innerHTML;
            if(ainnerHtml.toString().indexOf('ross Reference / Document Title =',0) > 0)
            {
              step.crossreffound = true;
            }
            if (ainnerHtml.toString().indexOf('[DataTrend]',0) > 0)
            {
              var temparray = ainnerHtml.toString().split(':____');
              var VarName = temparray[0];
              console.log(VarName);
              var dataTranding = {};
              dataTranding.uid = uuidv4();
              dataTranding.varname = VarName;
              dataTranding.varvalue = 'NULL';
              dataTranding.batchno = '';
              console.log(dataTranding);
              this.$set(step.dataTrandings, step.dataTrandings.length, dataTranding);
              step.datatrendfound = true;
            }
          }

        }
      step.steps = [];

      this.$set(sectionParent.steps, sectionParent.steps.length, step);
      //this.$set(sectionParent.steps, sectionParent.steps.length, step);

      editor.setData("");
    },
    addSection(e) {
      var el = e.target.parentElement.querySelector(".ckeditor-x");
      var editor = this.getEditorInstance(el);

      if (!this.documentTemp.sections) {
        this.$set(this.documentTemp, "sections", []);
      }
      var sec = {};
      sec.uid = uuidv4();
      sec.description = editor.getData();
      sec.steps = [];

      this.$set(
        this.documentTemp.sections,
        this.documentTemp.sections.length,
        sec
      );
      editor.setData("");
    },
    handleFileUploads(){
      this.files = this.$refs.file.files[0];
      console.log(this.files);
    },
    addImage(e){
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append('file', this.files);
      axios
          .post("/api/uploadVideosFiles",formData,
              {
                headers: {
                        'Content-Type': 'multipart/form-data'
                }
              }
          )
          .then(function(response) {
            console.log(response);
            //app.documentTemp = response.data.item;
            app.filespath = "";
            app.videopath = "";
            app.videotype = "";
            var path = response.data.fileUrl;
            console.log(response.data.fileUrl);
            console.log(response.data.fileName);
            console.log(response.data.uploaded);
            var type = response.data.fileName.split(".")[1];
            if(type == "mp4")
            {
              app.videopath = path.toString();
              app.videotype = response.data.type.toString();
            }
            else
            {
              app.filespath = path.toString();
            }
            
            alert("File Uploaded Successfully");
          })
          .catch(function(error) {
            console.log(error);
            //app.documentTemp = [];
          });

    },
    addTitle(e) {
      var el = e.target.parentElement.querySelector(".form-control-alternative");
      // var editor = window.CKEDITOR.instances[el.id];

      this.$set(this.documentTemp, "title", el.value);
      this.$set(this.documentTemp, "status1", "draft");
      this.$set(this.documentTemp, "approvedBy", this.authCookie.userName);
      this.$set(this.documentTemp, "userName", this.authCookie.userName);
      this.$set(this.documentTemp, "orgID", this.authCookie.orgs[0].orgID);
      this.$set(this.documentTemp, "createdBy", this.authCookie.userName);
      // this.$set(this.documentTemp, "updatedBy", this.authCookie.userName);

      // editor.setData("");
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    onValueChange(section,e){
       //console.log('change');
       section.defaultvalue = e.target.value;
       //console.log(section);
    },
    verify(){
      //console.log('verify');
      //console.log(this.finalarray);

      var i = 0;
      for(i=0;i<this.finalarray.length;i++)
      {
        if(this.finalarray[i].defaultvalue != this.finalarray[i].oldvalue)
        {
          //console.log('modify');
          var first = this.finalarray[i].sectionnum.toString().split('.')[0];
          var sec = this.finalarray[i].sectionnum.toString().split('.')[1];
          var oldstr = '[' + this.finalarray[i].variablename.toString() + ',' + this.finalarray[i].oldvalue.toString() + ']';
          var newstr = this.finalarray[i].variablename.toString() + ',' + this.finalarray[i].defaultvalue.toString();
          //console.log('oldstr');
          //console.log(oldstr);
          //console.log('newstr');
          //console.log(newstr);
          console.log(this.documentTemp.sections[first-1].steps[sec-1].description.toString().replace(oldstr,newstr));
          this.documentTemp.sections[first-1].steps[sec-1].description = this.documentTemp.sections[first-1].steps[sec-1].description.toString().replace(oldstr,newstr); 
        }

      }
      //this.documentTemp.sections[i].steps[j].description

        
    },
    setToDefault()
    {
      var i = 0;
      for(i=0;i<this.finalarray.length;i++)
      {
        this.finalarray[i].defaultvalue = this.finalarray[i].oldvalue;
      }
      this.showLaunchBatchModal = false;
      //console.log(this.finalarray);
    },
    launchBatch() {
      let app = this;
      app.verify();
      app.showLaunchBatchModal = false;
      this.deleteAttr(this.documentTemp);
      var batchDoc = JSON.parse(JSON.stringify(this.documentTemp));
      //batchDoc.docID = null;
      batchDoc.batchNumber = this.batchDocNumber;
      batchDoc.status1 = "batch";
      axios
        .post("/api/batches", batchDoc)
        .then(function(response) {
          console.log(response);
          app.showShareModal = false;
          alert("Batch started successfully!");
          if(batchDoc.status1 == 'batch') {
              app.notifyUserBatch(batchDoc);
            }
          app.setToDefault();
          // window.location = "/golive";
          window.location =
            "/documents?docStatusType="+ batchDoc.status1 +
            "&templateType=" +
            batchDoc.templateType;
        })
        .catch(function(error) {
          console.log(error);
          app.documentTemp = [];
        });
    },
    shareDocument() {
      let app = this;
      this.deleteAttr(this.documentTemp);
      if (app.isShare()) {
        this.documentTemp.emailShare = this.emailAddress;
        axios
          .put("/api/documents", app.documentTemp)
          .then(function(response) {
            console.log(response);
            app.documentTemp = response.data.item;
            alert("Document Shared Successfully!");
            
          })
          .catch(function(error) {
            console.log(error);
            app.documentTemp = [];
          });
      } else {
        var shareDoc = JSON.parse(JSON.stringify(this.documentTemp));
        // shareDoc.docID = null;
        shareDoc.parentDocID = this.documentTemp.docID;
        shareDoc.status1 = "shared";
        shareDoc.emailShare = this.emailAddress;
        axios
          .post("/api/documents", shareDoc)
          .then(function(response) {
            console.log(response);
            app.showShareModal = false;
            alert("Successfully shared document!");
            if(shareDoc.status1 == 'shared') {
              app.notifyUser(shareDoc);
            }
            window.location =
            "/documents?docStatusType="+ shareDoc.status1 +
            "&templateType=" +
            shareDoc.templateType;
            // window.location = "/documents/shared";
            //app.documentTemp = response.data.item;
          })
          .catch(function(error) {
            console.log(error);
            app.documentTemp = [];
          });
      }
    },
    shareLibraryDocument() {
      let app = this;
      this.deleteAttr(this.documentTemp);
      var shareDoc = JSON.parse(JSON.stringify(this.documentTemp));
      shareDoc.docID = null;
      shareDoc.parentDocID = this.documentTemp.docID;
      shareDoc.status1 = "template";
      axios
        .post("/api/documents", shareDoc)
        .then(function(response) {
          console.log(response);
          alert("Document successfully shared to DigitalGxP Public Library!");
        })
        .catch(function(error) {
          console.log(error);
          app.documentTemp = [];
        }).then(function() { app.showShareModal = false; });

    },
    async approveDocument() {
      this.documentTemp.approvedBy = this.authCookie.email;
      this.documentTemp.status1 = "approved";
      await this.saveDocument();
      this.showApproveModal = false;
      // window.location = "/documents/approved";
      window.location =
        "/new?docid=" +
        this.documentTemp.docID +
        "&templateType=" +
        this.documentTemp.templateType +
        '&docStatusType='+ this.documentTemp.status1;
    },
    versionupDocument() {
      let app = this;
      this.deleteAttr(this.documentTemp);
        var versionDoc = JSON.parse(JSON.stringify(this.documentTemp));
        versionDoc.parentDocID = this.documentTemp.docID;
        versionDoc.status1 = "versionup";
        axios
          .post("/api/documents", versionDoc)
          .then(function(response) {
            console.log(response);
            app.showVersionupModal = false;
            alert("Successfully versionup document!");
            window.location =
            "/documents?docStatusType="+ versionDoc.status1 +
            "&templateType=" +
            versionDoc.templateType;
          })
          .catch(function(error) {
            console.log(error);
            app.documentTemp = [];
          });
    },
    notifyUser(documentTempNotification){
      console.log(documentTempNotification);
      var notification = {
          objectId: documentTempNotification.docID,
          type: "document",
          status: documentTempNotification.status1,
          message:  " has " + documentTempNotification.status1  + " the Document " + documentTempNotification.title,
      };
     // this.$refs.childComponent.saveNotification(notification);
      this.$root.$emit('listenNotification', notification);
      
      //this.$router.push(documentTempNotification, notification);
      console.log(notification);
    },
    notifyUserBatch(documentTempNotification){
      console.log(documentTempNotification);
      var notification = {
          objectId: documentTempNotification.docID,
          type: "document",
          status: documentTempNotification.status1,
          message:  " has " + " launched " + documentTempNotification.status1  + " Document " + documentTempNotification.batchNumber + " for " + documentTempNotification.title,
      };
      this.$root.$emit('listenNotification', notification);
    },
    notifyUserComment(documentTempNotification){
      console.log(documentTempNotification);
      for( var i = 0; i < documentTempNotification.sections.length; i++) {
        var orginalString = documentTempNotification.sections[i].description;
        var strippedString = orginalString.replace(/(<([^>]+)>)/gi,"").trim();
        console.log(strippedString);
      for( var j = 0; j < documentTempNotification.sections[i].steps.length; j++ ) {
        var orginalStepString = documentTempNotification.sections[i].steps[j].description;
        var strippedStepString = orginalStepString.replace(/(<([^>]+)>)/gi,"").trim();
        if(documentTempNotification.sections[i].steps[j].comments ?  documentTempNotification.sections[i].steps[j].comments.length : 0){
          for( var k = 0; k < documentTempNotification.sections[i].steps[j].comments.length; k++) {
          var notification = {
              objectId: documentTempNotification.docID,
              type: "document",
              status: documentTempNotification.status1,
              message: " has created  comment for " + documentTempNotification.title + " " + documentTempNotification.status1  + " document under section " + this.commentSection + " " + strippedString + " step " + 
                              this.commentTitle + " " +  
                              strippedStepString + " comment description" + " " + documentTempNotification.sections[i].steps[j].comments[k].description
          };
          }
        }
      }
      }
      this.$root.$emit('listenNotification', notification);
    },
    onClickComment(step, secIndex, stepIndex) {
      if(  !(this.authCookie && this.authCookie.userName) )
      { 
        this.$emit("showLoginOnShared", this.docID);
        return;
      }
      this.currentStep = step;
      this.commentTitle = secIndex + 1 + "." + (stepIndex + 1);
      this.commentSection = secIndex + 1;
      this.showCommentModal = true;
      this.commentDescription = "";
    },
    addComment() {
      if (!this.currentStep.comments) this.currentStep.comments = [];
      var comment = {};
      comment.description = this.commentDescription;
      comment.createdBy = this.authCookie.email;
      comment.createdTime = new Date();
      this.currentStep.comments.push(comment);
      this.newComments.push(comment);
      this.notifyUserComment(this.documentTemp);
      this.showCommentModal = false;
      this.showCommentModal = true;
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    openShare(shareName, $event) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(shareName).style.display = "block";
      $event.target.className += " active";
    }
  }
  
};
</script>
<style>

.versionup{
  margin-left: 49rem !important;
  margin-bottom: 10px;
}
.modal-dialog-wider {
  max-width: 800px;
  margin: 1.75rem auto;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.copy-link{
  float: right;
  width: 10%;
  margin-top: -9px;
}
.edit-section{
  margin-top: 33px;
}
button.btn.float-right.btn-default.btn-sm.btn-step-action.section-edit {
  background-color: #172b4d;
  margin-right: 30px;
  /* margin-top: -5px; */
}
#editorControlListLeft
{
  height: calc(100vh - 200px) !important;
  overflow: scroll;
  width: 65px;
}
.comment-dialog {
  max-width: 65%;
}
.share-modal {
  max-width: 50%;
}
.document-step:hover {
  background-color: #f8f5fa;
}
/* .document-step:hover ~ .step-action-btn-group,
.step-action-btn-group:hover
{
    display: block;
} */
.document-step {
  background-color: transparent;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-header {
  background-color: #dadce0;
}
.comment-text {
  color: darkgray !important;
}
.comment-section {
  background-color: rgba(0, 0, 0, 0.02);
  padding-left: 10px;
}
.comment-header {
  margin-right: 15px;
  font-size: 13px;
  font-stretch: 100%;
  font-style: italic;
  font-weight: 500;
}
.comment-user {
  color: #385898;
}
div[data-oembed-url] > div > iframe {
  height: auto !important;
  width: auto !important;
}
div[data-oembed-url] > div {
  padding-bottom: 150px !important;
}
.editControlParent {
  width: 50px;
  position: fixed;
  flex-basis: auto;
}
.editRightParent {
  width: 100px;
  position: fixed;
  right: 45px;
  bottom: 50px;
  flex-basis: auto;
  margin-right: 10px;
}
.editorNewDocument {
  width: calc(100% - 320px);
  margin-left: 70px;
  margin-right: 120px;
  flex-basis: auto;
}
.time-input::after {
  content: url(/img/pptx/time-small.png);
}

.u-photo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 45px;
}
.leftIcon {
  font-size: 10px;
  position: relative;
  top: -10px;
}
.editRightbtn {
  margin-bottom: 10px;
}
.section-toggle-comment,
.sec-toggle {
  background-color: #0001;
  color: white;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}
.section-toggle-comment.sec-toggle.accordion:after,
.sec-toggle.accordion:after {
  content: "\002B";
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.card.shadow1 {
  border: 1px solid #dadce0 !important;
  padding: 6px;
}
.doc_header1 {
  font-weight: 700;
  text-align: center;
  /* top: -100px; */
  position: relative;
  /* left: 110px; */
  /* background-color: #ffff; */
  /* margin-right: 0px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0.375rem; */
  width: calc(100% - 270px);
}
.btn-step-action {
  background-color: inherit;
  border-color: transparent;
  color: inherit;
  z-index: 1001;
  top: 2px !important;
  margin-right: 2px;
}
.step-action-btn-group {
  display: block;
}
.commentCount
{
    position: absolute;
    top: 2px;
    /* left: 53px !important; */
    color: #000000f2 !important;
    font-size: 12px;
}
.btn-comment {
  width: 10px !important;
  padding-right: 22px;
  font-size: .8rem;
}
.btn {
  border-radius: 20px;
  width: 130px;
}
/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
em {
  display:none;
}
.tooltip1 {
  cursor: pointer;
}
 .tooltiptext {
  visibility: hidden;
  width: 450px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -270px;
  margin-top: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
 .tooltiptextimg {
  visibility: hidden;
  width: 450px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -11rem;
  margin-top: -7rem;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptextimg::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltip1:hover .tooltiptextimg {
  visibility: visible;
  opacity: 1;
}
.input-type-numeric{
  visibility: visible;
}
.input-type-text{
  visibility: visible;
}
html {
  overflow-x: hidden;
}
@media only screen and (max-width: 1024px) {
  .editRightParent {
    top: 30rem !important;
    margin-right: 22px !important;
}
.editorNewDocument {
    width: calc(100% - 200px) !important;
}
.media-edit{
  width: 50px !important;
}
.commentCount
{
    position: absolute;
    top: 2px;
    left: 14px !important;
    color: #000000f2;
    font-size: 12px;
}
}
.democlass {
  color: red;
}
.label-section{
  margin-top: 10px;
}
</style>
